<router-outlet></router-outlet>


<!-- Confirmation Modal -->
<ng-template #confirmationModal>
  <div class="modal-body text-center ">
      <span>{{confirmMessage}}</span>
    <div class="modal-footer d-flex align-items-end justify-content-center">
      <phx-button data-id="vendorAppDeclineBtn" text="Decline" (click)="appService.decline()" btnIcon="cancel-white" color="secondary" class="mr-2">
      </phx-button>
      <phx-button data-id="vendorAppConfirmBtn" text="Confirm" (click)="appService.confirm()" btnIcon="check-circle-white" color="primary" class="mr-2">
      </phx-button>
    </div>
  </div>
</ng-template>

<!-- Alert for errors -->
<div class="bottom-container">
  <div class="alert-container" *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout"><strong>{{ alert.title }}</strong></alert>
  </div>
</div>
