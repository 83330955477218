/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AuthService } from './auth-service';

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    constructor(protected authService: AuthService, protected router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve) => {
            Auth.currentSession()
                .then((currentSession) => {
                    if (currentSession && currentSession.isValid()) {
                        resolve(true);
                    } else {
                        this.authService.logout(true);
                        resolve(false);
                    }
                })
                .catch(err => {
                    this.authService.logout(true);
                    resolve(false);
                });
        })
    }
}