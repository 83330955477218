import { Component } from '@angular/core';
import { AuthService } from '@vapp/modules/auth/auth-service';
import { VendorTokenInfo } from '../../../../../phoenix-common/src/lib/shared/models/vendor-token-info';

@Component({
  selector: 'vendor-app-vendor-session',
  templateUrl: './vendor-session.component.html',
  styleUrls: ['./vendor-session.component.scss']
})
export class VendorSessionComponent {
  public decodedToken: VendorTokenInfo;

  constructor(
    private authService: AuthService
  ) {
    this.decodedToken = this.authService.getDecodedToken();
  }
}
