<ng-container *ngIf="loaded; else loader">
  <div class="modal-header">
    <h4 class="modal-title pull-left main-fields">{{ insuranceRequirementCategoryName }}</h4>

    <button (click)="bsModalRef.hide()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true" style="color:black">&times;</span>
    </button>
  </div>

  <div class="modal-body container-fluid">
    <div class="modal-section">
      <div class="modal-section-title">Category Details</div>
      <div class="modal-section-body p-3">
        <div class="inner-section">
          <div class="inner-section-title">Category Name</div>
          <div class="ml-3">{{ insuranceRequirementCategoryName }}</div>
        </div>
        <div class="inner-section">
          <div class="inner-section-title">Category Description</div>
          <div class="ml-3">{{ insuranceRequirementCategoryDescription }}</div>
        </div>
      </div>
    </div>

    <div class="modal-section">
      <div class="modal-section-title">Insurance Requirements</div>
      <div class="modal-section-body p-3 row">
        <div class="col-6">
          <phx-datatable [columns]="requirementsColumns" [rows]="requirementPolicies"
                         row-class="requirement-table-row" thead-class="requirement-table-head">
          </phx-datatable>
          <div *ngIf="!requirementPolicies || requirementPolicies.length < 1" class="text-center mt-2"> No
            Policies
          </div>
        </div>
        <div class="col-2">
          <phx-datatable [columns]="additionalTermsColumns" [rows]="additionalTerms"
                         row-class="requirement-table-row" thead-class="requirement-table-head"></phx-datatable>
          <div *ngIf="!endorsementPolicies || additionalTerms.length < 1" class="text-center mt-2"> No
            Additional Terms
          </div>
        </div>
        <div class="col-4">
          <phx-datatable [columns]="endorsementsColumns" [rows]="endorsementPolicies"
                         row-class="requirement-table-row" thead-class="requirement-table-head"></phx-datatable>
          <div *ngIf="!endorsementPolicies || endorsementPolicies.length < 1" class="text-center mt-2"> No
            Endorsements
          </div>
        </div>
      </div>
    </div>

    <div class="modal-section">
      <div class="modal-section-title">Documents Requirements</div>
      <div class="modal-section-body p-3 row">
        <div class="col-5">
          <phx-datatable [columns]="documentsColumns" [rows]="documents" [disabled]="true" [showTitleForCheckbox]="true"
                         row-class="requirement-table-row" thead-class="requirement-table-head"></phx-datatable>
          <div *ngIf="!documents || documents.length < 1" class="text-center mt-2"> No Documents</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <div class="modal-header">
    <h4 class="modal-title pull-left main-fields"></h4>
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true" style="color:black">&times;</span>
    </button>
  </div>

  <div class="modal-body container-fluid">
    <div class="modal-section">
      <phx-loader class="row h-100" size="big"></phx-loader>
    </div>
  </div>
</ng-template>
