import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'phx-checkbox',
  templateUrl: './phoenix-checkbox.component.html',
  styleUrls: ['./phoenix-checkbox.component.scss']
})
export class PhoenixCheckboxComponent {
  @Input() checkedText: string = '';
  @Input() uncheckedText: string = '';
  @Input() text: string = '';
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() dataTestId: string;
  @Output() clickEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  clicked(event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.clickEventEmitter.emit(event);
    }
  }

  changed(event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.changeEventEmitter.emit(event);
    }
  }
}
