import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClientContact } from '@vapp/models/client-contact-model';
import { ConnectionComplianceResponse } from '@vapp/models/connection-compliance-model';
import { JobPostingAlert } from '@vapp/models/job-posting-alert-model';
import { JobPosting } from '@vapp/models/job-posting-model';
import { JobsStatesResponse } from '@vapp/models/job-states-model';
import { VendorPreferenceGroup } from '@vapp/models/vendor-preference-group-model';
import { AppService } from '@vapp/modules/app/app.service';
import { AuthService } from '@vapp/modules/auth/auth-service';
import { SortingDirection } from 'projects/phoenix-common/src/public-api';
import { catchError, map } from 'rxjs/operators';
import { BaseWebService } from './base-web.service';

const jwtHelper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class PhoenixWebService extends BaseWebService {
  constructor(protected http: HttpClient, protected appService: AppService, protected authService: AuthService) {
    super(http, appService, authService);
    if (!(<any> window).vendorWebService) {
      (<any> window).vendorWebService = this;
    }
  }


  public async twillioAuth() {
    //widnow invokes this via window.vendorWebservice so dont remove this even though the IDE thinks it has no references
    const url = this.middleWareUri + '/api/vendors/conversations/tokens';
    return await this.apiGetCall(url);
  }

  public async getAllJobTrades() {
    let url = this.webServiceUri + '/VendorTrade';
    return await this.apiGetCall(url);
  }

  public async getAllJobTypes() {
    let url = this.webServiceUri + '/VendorJobType';
    return await this.apiGetCall(url);
  }

  // VENDOR PROFILE CALLS
  public async getLoggedVendorInfo() {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    let url = this.webServiceUri + `
		/VendorInfo?select=*,profile:VendorProfile(*),
		connections:VendorConnections(*),
		areas:VendorServiceArea(*),
		trades:VendorTrades(*,trade:VendorTrade(*)),
		contacts:VendorContact(*,location:VendorLocation(*,address:VendorAddresses(*)))
		&locations.contacts.primary=is.true&vendorId=eq.${this.getVendorId()}`;
    return await this.apiGetCall(url, headers);
  }

  public async getVendorPublicProfileWithConnections(vendorId) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    const url = this.webServiceUri + `/rpc/VendorPublicProfileWithConnections?vendor_id=${vendorId}`;
    return await this.apiGetCall(url, headers);
  }

  public async getAllClients(): Promise<Array<any>> {
    const url = this.webServiceUri + `/rpc/VendorHiringClientsWeb`;
    return await this.apiGetCall(url);
  }

  public async GetReviewsMeta(vendorId?: number) {
    if (!vendorId) {
      vendorId = this.getVendorId();
    }
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    let params = {
      vendorId: vendorId
    };
    let url = this.webServiceUri + '/rpc/GetReviewsMeta';
    return await this.apiPostCall(url, params, headers);
  }

  public async getReviewsbyVendorId(vendorId: number, page, perPage) {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);
    let url: string;
    if (this.getVendorId() == vendorId) {
      url = this.webServiceUri + `/VendorReviews?select=*,client:VendorClientInfo(name)&and=(vendorId.eq.${vendorId})&order=createdAt.desc`;
    } else {
      url = this.webServiceUri + `/rpc/VendorPublicReviews?vendor_id=${vendorId}`;
    }

    return await this.apiGetCallPaginated(url, headers);
  }

  public getVendorId(): number {
    let token = localStorage.getItem('authToken');
    if (token) {
      let res = jwtHelper.decodeToken(token);
      return res['vendorId'] || null;
    }

    return null;
  }

  public async lookupExistingVendors(taxid = '', vendorId = '') {
    let url = this.middleWareUri + '/api/vendors/registration/token?taxid=' + encodeURIComponent(taxid) + '&vendorId=' + encodeURIComponent(vendorId);
    return await this.apiGetCall(url);
  }

  public async registerExistingVendor(token: string, email: string, initialCompanyName: string, vendorId: number) {
    let url = this.middleWareUri + '/api/vendors/registration/invite';
    let body = {
      token: token,
      email: email,
      vendorId: vendorId,
      initialCompanyName: initialCompanyName
    };
    return await this.apiPostCall(url, body);
  }

  public async forgotPassword(email: string) {
    let url = this.middleWareUri + '/api/vendors/registration/reset_account';
    let body = {
      email: email
    };
    return await this.apiPostCall(url, body);
  }

  public async forgotVendorId(email: string) {
    let url = this.middleWareUri + '/api/forgot/vendor';
    let body = {
      email: email
    };
    return await this.apiPostCall(url, body);
  }

  public async getClientConnections() {
    let url = this.webServiceUri + `/VendorConnections?select=*,client:VendorClientInfo(*, config:VendorPublicClientConfig(*))&accepted=not.is.false`;
    return await this.apiGetCall(url);
  }

  // CONVERSATIONS CALLS

  public async getVendorDirectConversations() {
    let url = this.webServiceUri + '/VendorDirectConversations?select=*,client:VendorClientInfo(*, config:VendorPublicClientConfig(*))&hasMessages=is.true&order=lastUpdated.desc';
    return await this.apiGetCall(url);
  }

  public async getVendorBids(searchString: string = '') {
    let searchParamsStr = '';
    if (searchString && searchString.length > 0) {
      searchParamsStr += `&or=(clientName.ilike.*${searchString}*,jobDescription.ilike.*${searchString}*,jobTitle.ilike.*${searchString}*)`;
    }
    let url = this.webServiceUri + `/VendorJobBidsStatus?select=*,job:VendorJobs(*,locations:VendorJobLocation(*,location:VendorClientLocation(*)),types:VendorJobTypes(*),trades:VendorJobTrades(*),client:VendorClientInfo!FK_Jobs_Client(*,config:VendorPublicClientConfig(*)))&&order=lastUpdated.desc${searchParamsStr}`;
    return await this.apiGetCall(url);
  }

  public async updateBidBudgetRange(bidId, params) {
    await this.apiPatchCall(`${this.webServiceUri}/VendorJobBids?bidId=eq.${bidId}`, params);
    return true;
  }

  public async getLastReview() {
    let perPage = 1;
    let page = 1;

    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    return await this.apiGetCall(`${this.webServiceUri}/VendorReviews?select=*,client:VendorClientInfo(name)&order=createdAt.desc`, headers);
  }

  // EOF CONVERSATIONS CALLS

  // My Company
  public updateVendor(params) {
    let url = this.webServiceUri + `/VendorInfo?vendorId=eq.${params.vendorId}`;
    return this.apiPatchCall(url, params);
  }

  public updateVendorProfile(vendorId, params) {
    let url = this.webServiceUri + `/VendorProfile?vendorId=eq.${vendorId}`;
    return this.apiPatchCall(url, params);
  }

  public async updateVendorInfo(params: any) {
    let url = this.middleWareUri + '/api/vendors/vendor-info';
    return await this.apiPostFormCall(url, params);
  }

  public async addVendorServiceArea(params) {
    let url = this.webServiceUri + '/VendorServiceArea';
    return this.apiPostCall(url, params);
  }

  public async deleteServiceArea(vendorId, serviceAreaId) {
    let url = this.webServiceUri + `/VendorServiceArea?and=(vendorId.eq.${vendorId},serviceAreaId.eq.${serviceAreaId})`;
    return await this.apiDeleteCall(url);
  }

  public async editVendorServiceArea(params, vendorId, serviceAreaId) {
    let url = this.webServiceUri + `/VendorServiceArea?and=(vendorId.eq.${vendorId},serviceAreaId.eq.${serviceAreaId})`;
    return await this.apiPatchCall(url, params);
  }

  public async deleteVendorTrade(tradeId, vendorId) {
    let url = this.webServiceUri + `/VendorTrades?and=(vendorId.eq.${vendorId},tradeId.eq.${tradeId})`;
    return await this.apiDeleteCall(url);
  }

  public async deleteVendorTrades(vendorId) {
    let url = this.webServiceUri + `/VendorTrades?and=(vendorId.eq.${vendorId})`;
    return await this.apiDeleteCall(url);
  }

  public addVendorTrade(trades: Array<any>) {
    let url = this.webServiceUri + '/VendorTrades';
    return this.apiPostCall(url, trades);
  }

  public async updateVendorAvatar(params: any) {
    let url = this.middleWareUri + '/api/vendors/profile_image';
    return await this.apiPostFormCall(url, params);
  }

  public async uploadVendorMediaUrl(mediaType: string) {
    let url = this.middleWareUri + `/api/vendors/media/uploadUrl?mediaType=${mediaType}`;
    return await this.apiGetCall(url);
  }

  public async uploadToS3(preSignedUrl: string, mediaFile: File) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', mediaFile.type);
    headers = headers.set('x-amz-acl', 'public-read');
    return await this.http.put(preSignedUrl, mediaFile, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
  }

  public async deleteVendorMedia(mediaId: string) {
    const url = this.middleWareUri + `/api/vendors/media/delete/${mediaId}`;
    return await this.apiDeleteCall(url);
  }

  public async getVendorMedia(vendorId: number, mediaType: string, page, perPage, removedCount) {
    const rangeLow = (perPage * page) - perPage - removedCount;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    const url = this.webServiceUri + `/rpc/VendorMedia?vendor_id=${vendorId}&media_type=${mediaType}`;

    return await this.apiGetCallPaginated(url, headers);
  }


  public async getJobPostings(filters: any) {
    const res = await this.apiPostCall(`${this.webServiceUri}/rpc/VendorJobWebSearch`, filters);
    return new JobPosting().listFromJson(res);
  }

  public async getJobPostingAlert() {
    const res = await this.apiGetCall(`${this.webServiceUri}/VendorJobAlert?select=*,trades: VendorJobAlertTrade(id: tradeId), jobTypes: VendorJobAlertJobType(id: jobTypeId)`);
    if (res != null && res.length > 0) {
      return new JobPostingAlert().fromJSON(res[0]);
    } else {
      return new JobPostingAlert();
    }
  }

  public async createJobPostingAlert(req: any) {
    return await this.apiPostCall(`${this.webServiceUri}/rpc/CreateVendorJobAlert`, req);
  }

  public async getVendorNotifications(page, perPage, removedCount) {
    const rangeLow = (perPage * page) - perPage - removedCount;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    return await this.apiGetCallPaginated(`${this.webServiceUri}/rpc/VendorUserNotifications`, headers);
  }

  public async updateNotificationStatus(notification, status: { read?: boolean, removed?: boolean }) {
    const currentUserId = this.appService.getLoggedUser().cognitoId;
    const notificationStatus = {
      eventId: notification.eventId,
      userId: currentUserId,
      vendorUserId: currentUserId,
      ...status
    };
    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'resolution=merge-duplicates');
    const url = this.webServiceUri + `/VendorUserNotifications`;

    return await this.apiPostCall(url, notificationStatus, headers);
  }

  public async getJobPostingsWeb(filters: any) {
    return await this.apiPostCall(`${this.webServiceUri}/rpc/VendorJobWebSearch`, filters);
  }

  public async getHiringClients() {
    return await this.apiGetCall(`${this.webServiceUri}/rpc/VendorHiringClients`);
  }

  public async getJobPostingByJobId(jobId: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');
    return await this.apiPostCall(`${this.webServiceUri}/rpc/VendorGetJobById`, { jobPostingId: jobId }, headers);
  }

  public async getClientsConnectionsCompliance() {
    const res = await this.apiGetCall(`${this.middleWareUri}/api/vendors/compliance/connections`);
    return new ConnectionComplianceResponse().fromJSON(res);
  }

  public async getAssociatedClientJobs(clientId: number, vendorId: number): Promise<JobPosting[]> {
    const res = await this.apiGetCall(
      `${this.webServiceUri}/VendorJobs?&clientId=eq.${clientId}&vendorId=eq.${vendorId}`);
    let jobs = new JobPosting().listFromJson(res);
    for (let job of jobs) {
      // TODO: Change it to work with bulk request
      job.compliance = await this.getJobCompliance(job.jobId);
    }
    return jobs;
  }

  // TODO: Bulk request from Ryan !!
  public async getJobCompliance(jobId: string) {
    const res = await this.apiGetCall(
      `${this.middleWareUri}/api/vendors/compliance/certificates/${jobId}`);
    return res;
  }

  public async getJobsStates(jobIds: Array<any> = []) {
    const query = jobIds.map((id) => `jobIds=${id}`).join('&');
    const res = await this.apiGetCall(`${this.middleWareUri}/api/vendors/jobs/state?${query}`);
    return new JobsStatesResponse().fromJSON(res);
  }

  public async getClientContacts(clientId: number) {
    const res = await this.apiGetCall(`${this.webServiceUri}/VendorClientContact?clientId=eq.${clientId}`);
    return ClientContact.listFromJson(res);
  }

  public async updateClientConnectionStatus(connectionId: string, status: boolean) {
    await this.apiPutCall(`${this.middleWareUri}/api/vendors/connections/${connectionId}`, { 'accepted': status });
    return true;
  }

  public async addClientWithCode(code: string) {
    try {
      await this.apiPostCall(`${this.middleWareUri}/api/vendors/connections?code=${code}`);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async certusRegistration(connectionId: number, certusLink: string) {
    const encodedCertusLink = encodeURIComponent(certusLink);
    await this.apiPostCall(`${this.middleWareUri}/api/vendors/connections/${connectionId}/certus_link?connectionQueryString=${encodedCertusLink}`);
    return true;
  }

  public async getVendorSearch(params, page, perPage) {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    let url = this.webServiceUri + '/rpc/vendor_search';

    return await this.apiPostCallPaginated(url, headers, params);
  }

  // My Jobs
  public async getClientById(clientId) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    let url = this.webServiceUri + `/VendorClientInfo?clientId=eq.${clientId}`;
    return await this.apiGetCall(url, headers);
  }

  public async getAllJobs() {
    let headers = new HttpHeaders();


    let url = this.webServiceUri + `/rpc/VendorMyJobsSearch`;
    return this.apiGetCall(url, headers);
  }

  // Home
  public async getHomeMeta() {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    let url = this.webServiceUri + `/rpc/VendorWebHome`;
    return this.apiGetCall(url, headers);
  }

  public async downloadComplianceReportPDF(jobId) {
    const url = this.middleWareUri + `/api/vendors/compliance/jobs/${jobId}/pdfreport`;
    return await this.apiGetDownload(url);
  }

  public async getDocumentUrl(documentID) {
    let url = this.middleWareUri + `/api/vendors/documents/${documentID}`;
    return await this.apiGetCall(url);
  }

  public async getCreateJobBid(jobId) {
    let url = this.middleWareUri + `/api/vendors/conversations/clients/job-bid/${jobId}`;
    return await this.apiGetCall(url);
  }

  public async jobPostingView(jobId) {
    let url = this.webServiceUri + `/rpc/VendorJobView`;
    return await this.apiPostCall(url, { jobId: jobId });
  }

  public async sendRequestReview(requestReview) {
    let url = this.middleWareUri + '/api/vendors/review/request-review';
    return await this.apiPostCall(url, requestReview);
  }

  //CLIENT ADMIN CALLS
  public async getVendorUsers() {
    let url = this.middleWareUri + `/api/vendors/cognito/users`;
    return await this.apiGetCall(url);
  }

  public async getVendorUsersById(userId) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    let url = this.webServiceUri + `/VendorUser?vendorUserId=eq.${userId}`;
    return await this.apiGetCall(url, headers);
  }

  public async createNewVendorUser(params) {
    let url = this.middleWareUri + `/api/vendors/cognito/register`;
    return await this.apiPostCall(url, params);
  }

  public async updateVendorUser(params, userId) {
    let url = this.middleWareUri + `/api/vendors/cognito/edit-user/${userId}`;
    return await this.apiPostFormCall(url, params);
  }

  public async deleteVendorUser(userId) {
    let url = this.middleWareUri + `/api/vendors/cognito/user/${userId}`;
    return await this.apiDeleteCall(url);
  }

  public async resetVendorUserPassword(userId) {
    let url = this.middleWareUri + `/api/vendors/cognito/user/reset-password/${userId}`;
    return await this.apiPostCall(url);
  }

  // VENDOR PREFERENCES CALLS
  public async getVendorPreferenceGroups(): Promise<VendorPreferenceGroup[]> {
    const url = this.webServiceUri + `/rpc/VendorPreferenceGroups`;
    const res = await this.apiGetCall(url);
    return new VendorPreferenceGroup().listFromJson(res);
  }

  public async updatePreferenceValue(preferenceId, updatedValue): Promise<boolean> {
    const url = this.webServiceUri + `/rpc/VendorCreateUpdatePreference`;
    return await this.apiPostCall(url, { preference_id: preferenceId, updated_value: updatedValue });
  }

  public async getClientRegistrationPortalUrl(connectionId: number) {
    const url = this.middleWareUri + `/api/vendors/connections/${connectionId}/signup_url`;
    return await this.apiGetCall(url);
  }
}
