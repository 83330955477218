import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Amplify, Hub } from 'aws-amplify';
import { AppService } from '../app.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { PhoenixChatService } from 'phoenix-common';
import { GainsightService } from '@vapp/providers/gainsight.service';
import { combineLatest, Subscription } from 'rxjs';
import { environment } from '@vapp/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('confirmationModal', {static: true}) modalTemplateRef: TemplateRef<any>;
  public confirmMessage: string;
  public modalRef: BsModalRef;
  public alerts: Array<any> = [];
  public alertMessageSub: Subscription;
  public confirmModalSub: Subscription;

  constructor(
    public appService: AppService,
    private phoenixChatService: PhoenixChatService,
    private gsService: GainsightService) {
    Amplify.configure({
      Auth: {
        region: 'us-east-1',
        identityPoolRegion: 'us-east-1',
        userPoolId: environment.cognitoUserPoolId,
        userPoolWebClientId: environment.cognitoUserPoolWebClientId
      }
    });

    this.confirmModalSub = this.appService.modalConfirmTitleSubject.subscribe((title: string) => {
      this.confirmMessage = title;
    });

    this.alertMessageSub = this.appService.alertMessageSubject.subscribe((res) => {
      this.alerts.push({
        title: res.title,
        type: res.type,
        timeout: 5000
      });
    });

    Hub.listen('auth', (data) => {
      const { payload } = data;
      switch (payload.event) {
        case 'signIn':
          this.handleSignIn();
          break;
        case 'signOut':
          this.handleSignOut();
          break;
          // Add more cases if needed
      }
    });
    this.init();
  }

  ngAfterViewInit(): void {
    this.appService.modalRef = this.modalRef;
    this.appService.modalConfirmTemplate = this.modalTemplateRef;
  }

  ngOnDestroy(): void {
    this.confirmModalSub.unsubscribe();
    this.alertMessageSub.unsubscribe();
  }

  handleSignIn() {
    setTimeout(async () => {
      await this.phoenixChatService.connect();
    });
  }

  handleSignOut() {
    setTimeout(() => {
      this.gsService.logout();
    });
  }

  public async init(): Promise<void> {

    combineLatest([this.appService.loggedVendor, this.appService.loggedUser]).subscribe((res) => {
      if (res[0] && res[1]) {
        const loggedVendor: any = res[0];
        const loggedUser: any = res[1];

        this.gsService.identify(loggedUser.cognitoId, loggedUser.email, loggedUser.name, loggedVendor.vendorId, loggedVendor.name);
      }
    });
  }
}
