import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@capp/app/services/app.service';
import { PhoenixWebService } from '@vapp/providers/web.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
	ViewRequirementsModalComponent
} from '@capp/app/features/sidebar-features/client-settings/requirements/view-requirements-modal/view-requirements-modal.component';

@Component({
	selector: 'job-posting-details',
	templateUrl: './job-posting-details-modal.html',
	styleUrls: ['./job-posting-details-modal.scss']
})
export class JobPostingDetailsModal {
	public jobId;
	public job: any;
	public requirementOpen: boolean = false;

	constructor(
		private phoenixWebService: PhoenixWebService,
		private bsModalRef: BsModalRef,
		private appService: AppService,
		private router: Router,
		private modalService: BsModalService,
	) {

	}

	async ngAfterViewInit() {
		if (this.jobId) {
			this.job = await this.phoenixWebService.getJobPostingByJobId(this.jobId);
			try {
				//update job views count
				this.phoenixWebService.jobPostingView(this.jobId);
			} catch (err) {
				console.log(err);
			}
		}
	}

	public closeModal() {
		this.bsModalRef.hide();
	}

	public async iWantToBid() {
		try {
			this.job = null;
			await this.phoenixWebService.getCreateJobBid(this.jobId)
			this.job = await this.phoenixWebService.getJobPostingByJobId(this.jobId);
		} catch (err) {
			console.log(err);
			this.closeModal();
			this.appService.showAlertMessage('Something went wrong!', 'danger');
		}
	}

	public viewConversation() {
		this.router.navigate(['/main', 'messages', 'opened-job-postings', this.jobId, this.job.conversationId]);
		this.closeModal();
	}

	openRequirementsModal() {
		const initialState = {
			requirementId: this.job?.requirementCategoryId,
			isVendor: true
		}
		this.requirementOpen = true;
		this.modalService.show(ViewRequirementsModalComponent, {class: 'modal-xl',initialState: initialState}).onHide.subscribe(() => {
			this.requirementOpen = false;
		});
	}
}

