<ng-container *ngIf="!requirementOpen">
	<div class="modal-header">
		<h4 class="modal-title pull-left">Job Posting Details</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body h-100" *ngIf="!job">
		<phx-loader size="big" class="h-100"></phx-loader>
	</div>

	<div class="row p-4" *ngIf="job">
		<div class="col-8">
			<div class="row p-2">
				<div class="col-12 box-container h-100">
					<div class="row h-100" style="min-height: 155px;">
						<div class="col-8 d-flex">
							<div class="job-posting-image">
                <phx-icon [url]="job?.client?.clientLogo" size="100" src="profile-placeholder.png"></phx-icon>
							</div>
							<div class="ml-2">
								<div class="client-name mt-2"><b>{{job?.client?.name}}</b></div>
								<div class="job-description bold">{{job.title}}</div>
							</div>
						</div>
						<div class="col-4 d-flex flex-column align-items-end mb-2"
							[class.justify-content-between]="job?.bidding" [class.justify-content-end]="!job?.bidding">
							<div class="bidding-badge mt-3" *ngIf="job?.bidding">
								Bidding
							</div>
							<div class="text-right fs-14">
								<div class="d-flex">
									<div class="modal-section-icon posting-expiration mr-1"></div>
									<b>Posting Expiration</b>
								</div>
								<div>{{job?.expirationDate | date}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row p-2">
				<div class="col-12 box-container p-3">
					<div class="row">
						<div class="col">
							<div class="row">
								<div class="col-4 d-flex mb-2">
									<div class="fs-14">
										<div class="d-flex">
											<div class="modal-section-icon job-type mr-1"></div>
											<b>Job Types</b>
										</div>
										<div>{{job.jobTypes | jobTypePipe}}</div>
									</div>
								</div>
								<div class="col-4 d-flex mb-2 justify-content-center">
									<div class="text-right fs-14">
										<div class="d-flex">
											<div class="modal-section-icon project-start mr-1"></div>
											<b>Project Start</b>
										</div>
										<div>{{job?.jobStartDate | date}}</div>
									</div>
								</div>
								<div class="col-4 d-flex mb-2 justify-content-end">
									<div class="text-right fs-14">
										<div class="d-flex">
											<div class="modal-section-icon project-start mr-1"></div>
											<b>Project End</b>
										</div>
										<div>{{job?.jobEndDate | date}}</div>
									</div>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col">
									<div class="d-flex mb-2">
										<div class="modal-section-icon job-trade"></div>
										<div class="ml-2 container-title">Job Trades</div>
									</div>
									<div class="job-trades" tooltip="{{ job?.jobTrades | jobTradePipe }}"
										containerClass="service-tooltip">
										{{job.jobTrades | jobTradePipe}}
									</div>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col">
									<div class="d-flex mb-2">
										<div class="modal-section-icon job-desctiption"></div>
										<div class="ml-2 container-title">Job Description</div>
									</div>
									<div>
										{{job.description}}
									</div>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col">
									<div class="d-flex mb-2">
										<phx-icon customClass="standardImage" src="vendor-profile-black"></phx-icon>
										<div class="ml-2 container-title">Attachment(s)</div>
									</div>
									<div class="d-flex flex-column">
										<a *ngFor="let attachment of job.attachments" [href]="attachment.attachmentUrl"
											target="_blank">{{attachment.fileName}}
										</a>
									</div>
									<div *ngIf="!job?.attachments?.length">No files attached</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-4">
			<div class="row p-2">
				<div class="col-12 box-container p-3 h-100">
					<div class="d-flex mb-2">
						<phx-icon customClass="standardImage" src="vendor-profile-black"></phx-icon>
						<div class="ml-2 container-title">Contact</div>
					</div>
					<div>{{job?.client?.contacts[0]?.name}}</div>
					<div>{{job?.client?.contacts[0]?.title}}</div>
					<div>{{job?.client?.contacts[0]?.phone | phone}}</div>
					<div>{{job?.client?.contacts[0]?.email}}</div>
				</div>
			</div>

			<div class="row p-2">
				<div class="col-12 box-container p-3">
					<div class="d-flex mb-2">
						<div class="modal-section-icon locations"></div>
						<div class="ml-2 container-title">Job Location(s)</div>
					</div>
					<div style="max-height: 300px; overflow: auto;">
						<div *ngFor="let location of job?.clientLocations; let i = index;">
							{{i + 1}}. {{location.address + ', ' + location.city + ', ' + location.state + ', ' +
							location.zip}}
						</div>
					</div>
				</div>
			</div>

			<div class="row p-2">
				<div class="col-12 box-container p-3">
					<div class="d-flex mb-2">
						<div class="modal-section-icon job-requirements"></div>
						<div class="ml-2 container-title">Job Compliance Requirements</div>
					</div>
					<ng-container *ngIf="job?.requirementCategoryId">
						Client's Requirements for the Job:
						<span class="link-style pl-2 hand" (click)="openRequirementsModal()">View Requirements</span>
					</ng-container>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<ng-container *ngIf="!job?.bidding">
			<phx-button data-id="vendorJobPostingDetailsBidBtn" text="I Want To Bid" color="primary" (click)="iWantToBid()" btnIcon="like-button-white" size="big">
			</phx-button>
		</ng-container>
		<ng-container *ngIf="job?.bidding">
			<phx-button data-id="vendorViewConversationBtn" text="View Conversation" color="light-blue" (click)="viewConversation()" btnIcon="message-white" size="big">
			</phx-button>
		</ng-container>
	</div>
</ng-container>
