<input [(ngModel)]="asyncSelected"
       [formControl]="getFormControl()"
       (focusout)="focusout()"
       (keydown)="keydown($event)"
       [typeaheadAsync]="true"
       [typeahead]="dataSource"
       (typeaheadLoading)="changeTypeaheadLoading($event)"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"
       [typeaheadOptionsLimit]="5"
       typeaheadMinLength="3"
       typeaheadOptionField="address"
       [placeholder]="value && value.address ? value.address : placeholder"
       [readonly]="readonly"
       [ngClass]="('phx-input large') + ' ' + (hasError ? 'error' : '') + ' ' + size"
       data-testid="location-typeahead-input"
       class="{{classes}}"
       (ngModelChange)="searchInputChanged($event)"
>

