import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators'
import { throwError, Subject } from 'rxjs'
import { environment } from '../environments/environment';
import { AppService } from '@vapp/modules/app/app.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@vapp/modules/auth/auth-service';

const jwtHelper = new JwtHelperService();

export interface ErrorResponse {
    status: number,
    message: string
}

export interface SuccessResponse {
    summary: string,
    detail: string
}

export class BaseWebService {
    public webServiceUri: string = environment.webServiceUri;
    public middleWareUri: string = environment.middlewareUri;
    protected basePathWebService: string = "";
    public static errorSubject = new Subject<ErrorResponse>();
    public static successSubject = new Subject<SuccessResponse>();

    constructor(protected http: HttpClient, protected appService: AppService, protected authService: AuthService, basePath?: string) {
        this.webServiceUri = environment.webServiceUri;

        if (this.webServiceUri.endsWith('/')) {
            this.webServiceUri = this.webServiceUri.substring(0, this.webServiceUri.length - 1);
        }

        if (basePath) {
            if (!basePath.startsWith("/")) {
                basePath = "/" + basePath;
            }
            this.basePathWebService = this.webServiceUri + basePath
            if (!this.basePathWebService.endsWith("/")) {
                this.basePathWebService += "/";
            }
        }
    }

    protected extractData(res: Response) {
        return res || {};
    }

    protected handleError(error: Response | any) {
        let errorObj = null;

        try {
            errorObj = error.text().trim();
        // eslint-disable-next-line no-empty
        } catch (ex) { }

        try {
            errorObj = JSON.parse(errorObj);
            let err = errorObj[Object.keys(errorObj)[0]];

            BaseWebService.errorSubject.next({ status: error.status, message: err });
        // eslint-disable-next-line no-empty
        } catch (e) { }

        if (errorObj) {
            return throwError(errorObj);
        } else if (error && error.text) {
            return throwError(error.text().trim());
        } else {
            return throwError(error);
        }
    }

    protected async apiGetCall(url: string, headers?: HttpHeaders): Promise<any> {
        if (!headers) {
            headers = new HttpHeaders();
        }
        headers = headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }


        return this.http.get(url, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError.bind(this))).toPromise();
    }

    protected extractDataPaginated(response: HttpResponse<any>) {
        let rawRange = response.headers.get('content-range').split("/");
        const range = rawRange[0];
        const totalCount = rawRange[1];

        let res: { range: string; totalCount: number; items: Array<any> } = {
            range: range,
            totalCount: parseInt(totalCount),
            items: response.body
        }

        return res || {};
    }

    protected async apiGetDownload(url: string, headers?: HttpHeaders) {
        if (!headers) {
            headers = new HttpHeaders();
        }
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
            headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        }

        let response = await this.http.get(url, { headers: headers, responseType: 'blob', observe: 'response' }).pipe(map(this.downloadFile), catchError(this.handleError.bind(this))).toPromise();


        return response;
    }

    protected async apiGetBase64(url: string, headers?: HttpHeaders) {
        if (!headers) {
            headers = new HttpHeaders();
        }
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
            headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        }

        let response = await this.http.get(url, { headers: headers, responseType: 'text', observe: 'response' }).pipe(map(this.downloadBase64File), catchError(this.handleError.bind(this))).toPromise();

        return response;
    }

    protected async downloadFile(response) {
        let blob = new Blob([response.body], { type: 'application/pdf' });
        // var filename = 'compliance-report.pdf';
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);

        return true;
    }

    protected async downloadBase64File(response) {
        const byteArray = new Uint8Array(atob(response.body).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);

        return true;
    }

    protected async apiGetCallPaginated(url: string, headers?: HttpHeaders) {
        if (!headers) {
            headers = new HttpHeaders();
        }
        headers = headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }

        let response = await this.http.get(url, { headers: headers, observe: 'response' }).pipe(catchError(this.handleError.bind(this))).toPromise();
        return this.extractDataPaginated(response);
    }

    protected async apiPostCallPaginated(url: string, headers: HttpHeaders, params: any) {
        headers = headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }

        let response = await this.http.post(url, params, { headers: headers, observe: 'response' }).pipe(catchError(this.handleError.bind(this))).toPromise();
        return this.extractDataPaginated(response);
    }

    protected async apiPutCall(url: string, params?: any, headers?: HttpHeaders): Promise<any> {
        if (!headers) {
            headers = new HttpHeaders();
        }
        headers = headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }

        if (!params) {
            params = {};
        }
        return this.http.put(url, params, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

    protected async apiPatchCall(url: string, params?: any, headers?: HttpHeaders): Promise<any> {
        if (!headers) {
            headers = new HttpHeaders();
        }
        headers = headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }

        if (!params) {
            params = {};
        }
        return this.http.patch(url, params, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

    protected async apiRawCall(url: string, headers?: HttpHeaders): Promise<any> {
        if (!headers) {
            headers = new HttpHeaders();
        }
        headers = headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }

        return this.http.get(url, { headers: headers }).pipe(catchError(this.handleError.bind(this))).toPromise();
    }

    protected async apiDeleteCall(url: string, headers?: HttpHeaders): Promise<any> {
        if (!headers) {
            headers = new HttpHeaders();
        }
        headers = headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }

        return this.http.delete(url, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

    protected async apiPostCall(url: string, params?: any, headers?: HttpHeaders): Promise<any> {
        if (!headers) {
            headers = new HttpHeaders();
        }
        headers = headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }


        if (!params) {
            params = {};
        }
        return this.http.post(url, params, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

    protected async apiPostFormCall(url: string, params?: any, headers?: HttpHeaders): Promise<any> {
        if (!headers) {
            headers = new HttpHeaders();
        }
        let token = localStorage.getItem("authToken");
        if (token) {
            if (jwtHelper.isTokenExpired(token)) {
                this.authService.logout();
            }
            headers = headers.set('Authorization', 'Bearer ' + token);
        }


        if (!params) {
            params = {};
        }
        return this.http.post(url, params, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

}
