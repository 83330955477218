import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@capp/app/services/app.service';
import { PhoenixWebService } from '@vapp/providers/web.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VendorCertificate } from '@vapp/models/vendor-certificate-model';
import { ViewRequirementsModalComponent } from '@vapp/modules/my-job-postings/view-requirements-modal/view-requirements-modal.component';
import { PipeService } from 'phoenix-common';

@Component({
  selector: 'vendor-posting-details',
  templateUrl: './posting-details-modal.component.html',
  styleUrls: ['./posting-details-modal.component.scss']
})
export class PostingDetailsModalComponent implements AfterViewInit {
  public posting: VendorCertificate;
  public requirementOpen = false;
  show: boolean;

  constructor(private phoenixWebService: PhoenixWebService,
              private bsModalRef: BsModalRef,
              private appService: AppService,
              private router: Router,
              private modalService: BsModalService,
              public pipeService: PipeService) {

  }

  async ngAfterViewInit() {
    if (this.posting.certificateId) {
      try {
        //update job views count
        await this.phoenixWebService.jobPostingView(this.posting.certificateId);
      }
      catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  }

  public closeModal() {
    this.bsModalRef.hide();
  }

  public async iWantToBid() {
    try {
      await this.phoenixWebService.getCreateJobBid(this.posting?.postingId);
      this.posting = await this.phoenixWebService.getJobPostingByJobId(this.posting.certificateId);
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      this.closeModal();
      this.appService.showAlertMessage('Something went wrong!', 'danger');
    }
  }

  public async viewConversation() {
    await this.router.navigate([
      '/main',
      'messages',
      'opened-job-postings',
      this.posting.certificateId,
      this.posting.conversationId
    ]);
    this.closeModal();
  }

  openRequirementsModal() {
    const initialState = {
      requirementId: this.posting?.requirementCategoryId,
    };
    this.requirementOpen = true;
    this.modalService.show(ViewRequirementsModalComponent, {
      class: 'modal-xl',
      initialState: initialState
    }).onHide.subscribe(() => {
      this.requirementOpen = false;
    });
  }

  getClientContactName() {
    const clientContacts = this.posting?.client?.clientContacts ?? [];
    return clientContacts[0]?.name;
  }

  getClientContactTitle() {
    const clientContact = this.posting?.client?.clientContacts ?? [];
    return clientContact[0]?.title;
  }

  getClientContactPhone() {
    const clientContact = this.posting?.client?.clientContacts ?? [];
    return clientContact[0]?.phone;
  }

  getClientContactEmail() {
    const clientContact = this.posting?.client?.clientContacts ?? [];
    return clientContact[0]?.email;
  }
}

