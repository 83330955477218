<ng-container *ngIf="!requirementOpen">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Job Posting Details</h4>
    <button (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="!posting" class="modal-body h-100">
    <phx-loader [useCustomLoader]="true" class="h-100" size="big"></phx-loader>
  </div>

  <div *ngIf="posting" class="row p-4 modal-body">
    <div class="col-8 p-0">
      <div class="p-2">
        <div class="profile-box-container box-container">
          <div class="posting-image-container">
            <img [ngSrc]="posting?.client?.clientLogo" alt="profile-placeholder.png" height="150" width="150" />
          </div>
          <div class="posting-info-container">
            <div class="posting-dates-container">
              <div class="date-container">
                <label for="project-starts">Starts:</label>
                <div id="project-starts">{{ posting?.startDate | date }}</div>
              </div>
              <div class="date-container">
                <label for="project-ends">Ends:</label>
                <div id="project-ends">{{ posting?.endDate | date }}</div>
              </div>
              <div class="date-container">
                <label for="posting-expires">Expires:</label>
                <div id="posting-expires">{{ posting?.expirationDate | date }}</div>
              </div>
              <div *ngIf="posting?.isBidding"
                   class="bidding-badge">
                Bidding
              </div>
            </div>
            <div class="client-name mt-2"><b>{{ posting?.client?.name }}</b></div>
            <div class="posting-title">{{ posting.title }}</div>
          </div>
        </div>
      </div>
      <div class="p-2">
        <div class="description-box-container box-container">
          <div class="description-container">
            <div class="container-title">Job Trades</div>
            <ng-container *ngIf="posting?.trades?.length > 0; else noTradesContainer;">
              <ul class="columns" data-columns="2">
                <ng-container *ngIf="!show">
                  <li *ngFor="let trade of posting?.trades | slice:0:6">
                    {{pipeService?.jobTradesMaps[trade]}}
                  </li>
                </ng-container>
                <ng-container *ngIf="show">
                  <li *ngFor="let trade of posting?.trades">{{pipeService?.jobTradesMaps[trade]}}</li>
                </ng-container>
              </ul>
              <div class="show-hidden text-center" *ngIf="posting.trades.length > 5" (click)="show = !show"
                   style="color: var(--bcs-violet); cursor:pointer">
                {{show ? 'Show less' : 'Show more'}}
              </div>
            </ng-container>
            <ng-template #noTradesContainer>
              <div>No Trades Found</div>
            </ng-template>
          </div>
          <div class="description-container">
            <div class="container-title">Job Description</div>
            <div>
              {{ posting.description }}
            </div>
          </div>
          <br />
          <div class="description-container">
            <div class="attachments-title-container">
              <!--              <phx-icon customClass="standardImage" src="attachment-paperclip" color="black"></phx-icon>-->
              <div class="container-title">Attachment(s)</div>
            </div>
            <div class="d-flex flex-column">
              <a *ngFor="let attachment of posting.attachments" [href]="attachment.attachmentUrl"
                 target="_blank">{{ attachment.fileName }}
              </a>
            </div>
            <div *ngIf="!posting?.attachments?.length"><i>No files attached</i></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-4 p-0">
      <div class="p-2">
        <div class="contact-box-container box-container">
          <div class="contact-img-container">
            <phx-icon customClass="standardImage" extraClass="contact-img" size="75"
                      src="profile-placeholder.png"></phx-icon>
          </div>
          <div class="contact-container">
            <div class="contact-name-container">
              <div class="name">{{ getClientContactName() }}</div>
              <div class="title">{{ getClientContactTitle() }}</div>
            </div>
            <div>{{ getClientContactPhone() | phone }}</div>
            <div>{{ getClientContactEmail() }}</div>
          </div>
        </div>
      </div>
      <div class="p-2">
        <div class="description-box-container box-container">
          <div class="container-title">Job Location(s)</div>
          <div style="max-height: 300px; overflow: auto;">
            <div *ngFor="let location of posting?.clientLocations; let i = index;">
              {{ i + 1 }}. {{
                location.address + ', ' + location.city + ', ' + location.state + ', ' +
                location.zip
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="p-2">
        <div class="description-box-container box-container">
          <div class="container-title">Job Compliance Requirements</div>
          <ng-container *ngIf="posting?.requirementCategoryId">
            Client's Requirements for the Job:
            <span (click)="openRequirementsModal()" class="link-style pl-2 hand">View Requirements</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="!posting?.isBidding">
      <phx-button (click)="iWantToBid()" btnIcon="like-button-white" color="primary"
                  data-id="vendorJobPostingDetailsBidBtn"
                  size="big" text="I Want To Bid">
      </phx-button>
    </ng-container>
    <ng-container *ngIf="posting?.isBidding">
      <phx-button (click)="viewConversation()" btnIcon="message-white" color="light-blue"
                  data-id="vendorViewConversationBtn" size="big" text="View Conversation">
      </phx-button>
    </ng-container>
  </div>
</ng-container>
