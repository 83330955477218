import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.provider';
import { MainComponent } from './main/main.component';
import { VendorSessionComponent } from '@vapp/modules/app/vendor-session/vendor-session.component';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main', canActivate: [AuthGuard], component: MainComponent, children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadChildren: () => import('@vapp/modules/home/home.module').then(m => m.HomeModule) },
      {
        path: 'my-company',
        loadChildren: () => import('@vapp/modules/vendor-profile/vendor-profile.module').then(m => m.VendorProfileModule)
      },
      {
        path: 'vendor/:id',
        loadChildren: () => import('@vapp/modules/vendor-profile/vendor-profile.module').then(m => m.VendorProfileModule)
      },
      { path: 'my-clients', loadChildren: () => import('@vapp/modules/my-clients/my-clients.module').then(m => m.MyClientsModule) },
      { path: 'my-jobs', loadChildren: () => import('@vapp/modules/my-jobs/my-jobs.module').then(m => m.MyJobsModule) },
      {
        path: 'my-job-postings',
        loadChildren: () => import('@vapp/modules/my-job-postings/my-job-postings.module').then(m => m.MyJobPostingsModule)
      },
      { path: 'bcs-network', loadChildren: () => import('@vapp/modules/bcs-network/bcs-network.module').then(m => m.BCSNetworkModule) },
      { path: 'messages', loadChildren: () => import('@vapp/modules/messages/messages.module').then(m => m.MessagesModule) },
      { path: 'company', loadChildren: () => import('@vapp/modules/company/company.module').then(m => m.CompanyModule) },
      { path: 'me', loadChildren: () => import('@vapp/modules/user-settings/user-settings.module').then(m => m.UserSettingsModule) },
      { path: 'coming-soon', loadChildren: () => import('@vapp/modules/comming-soon/comming-soon.module').then(m => m.CommingSoonModule) },
      { path: 'session', component: VendorSessionComponent }

    ]
  },
  { path: 'login', loadChildren: () => import('@vapp/modules/login/login.module').then(m => m.LoginModule) },
  { path: 'vendor', loadChildren: () => import('@vapp/modules/public/public.module').then(m => m.PublicModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
