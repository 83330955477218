import { RegistrationStatus } from 'phoenix-common';
import { ComplianceStatus } from '../utils/Enumerations';
import { IBaseModel } from './base-model';

export class ConnectionCompliance implements IBaseModel {
    connectionId: number;
    clientId: number;
    vendorId: number;

    compliant: string;
    registrationStatus: string;

    compliantStatus: ComplianceStatus;
    registrationStatusEnum: RegistrationStatus;

    public fromJSON(json: any): ConnectionCompliance {
        if (json == null) return;

        this.connectionId = json['connectionId'];
        this.clientId = json['clientId'];
        this.vendorId = json['vendorId'];
        this.compliant = json['compliant'];
        this.registrationStatus = json['registrationStatus'];

        this.compliantStatus = ComplianceStatus[this.compliant];
        this.registrationStatusEnum = RegistrationStatus[this.registrationStatus];
        return this;
    }

    public toJSON() {
        return {
            'connectionId': this.connectionId,
            'clientId': this.clientId,
            'vendorId': this.vendorId,
            'compliant': this.compliant,
        };
    }

    public listFromJson(json) {
        return json == null ? new Array<ConnectionCompliance>() : json.map((value) => new ConnectionCompliance().fromJSON(value));
    }
}

export class ConnectionComplianceResponse implements IBaseModel {

    connectionsCompliance: Array<ConnectionCompliance>;

    public fromJSON(json: any) {
        if (json == null) return;
        this.connectionsCompliance = new ConnectionCompliance().listFromJson(json['connectionsCompliance']);
        return this;
    }

    static listFromJson(json: any): Array<ConnectionComplianceResponse> {
        return json == null ? new Array<ConnectionComplianceResponse>() : json.map((value) => new ConnectionComplianceResponse().fromJSON(value));
    }

    public toJSON() {
        return {};
    }

    static mapFromJson(json: any): Map<string, ConnectionComplianceResponse> {
        let map = new Map<string, ConnectionComplianceResponse>();
        if (json != null && json.length > 0) {
            json.forEach((key: string, value: Map<string, any>) => map[key] = new ConnectionComplianceResponse().fromJSON(value));
        }
        return map;
    }
}