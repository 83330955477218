import { IBaseModel } from './base-model';
import { VendorPreference } from './vendor-preference-model';

export class VendorPreferenceGroup implements IBaseModel {

    public id: string;
    public name: string;
    public groupPreferences: VendorPreference[];

    public fromJSON(json: any): VendorPreferenceGroup {
        if (json == null) return;

        this.id = json["id"];
        this.name = json["name"];
        this.groupPreferences = new VendorPreference().listFromJson(json["groupPreferences"]);

        return this;
    }

    public listFromJson(json: any): Array<VendorPreferenceGroup> {
        return json == null ? new Array<VendorPreferenceGroup>() : json.map((value) => new VendorPreferenceGroup().fromJSON(value));
    }

    public toJSON(): any {
        return {};
    }
}
