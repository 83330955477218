export enum CredentialType {
    NY_CERTIFIED,
    VERIFIED_LICENSES,
    SCREENED_FINANCIAL,
    SCREENED_BACKGROUND_CHECK,
}

export enum ComplianceStatus {
    NON_COMPLIANT,
    COMPLIANT,
    NONE,
}
