import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Icon component for all our BCS Phoenix Icons
 *
 * @example
 *  <phx-icon
 *    src="srcString"
 *    size="size"
 *    maxSize="maxSize"
 *    extraClass="class"
 *    color="color"
 *    ml="10"
 *    mb="10"
 *    mr="10"
 *    h="10"
 *    w="10"
 *    url="url-stuff"
 *    customClass="class-name"
 *    [isTooltipComponent]="false">
 *  </phx-icon>
 */
@Component({
  selector: 'phx-icon',
  templateUrl: './phoenix-icon.component.html',
  styleUrls: ['./phoenix-icon.component.scss']
})
export class PhoenixIconComponent implements OnInit {
  /**
   * #### src: string
   *  Image src link
   * @type {string}
   */
  @Input() src: string;

  /**
   * #### size: string
   *  Size of the icon
   * @type {string}
   */
  @Input() size: string;

  /**
   * #### maxSize: string
   *  The max size of the icon
   * @type {string}
   */
  @Input() maxSize: string;

  /**
   * #### extraClass: string
   *  Css class for the icon
   * @type {string}
   */
  @Input() extraClass: string;

  /**
   * #### color: string
   *  BCS specific color for the icon
   * @type {string}
   */
  @Input() color: string;

  /**
   * #### ml: string
   *  Margin Left
   * @type {string}
   */
  @Input() ml: string;

  /**
   * #### mb: string
   *  Margin Bottom
   * @type {string}
   */
  @Input() mb: string;

  /**
   * #### mr: string
   *  Margin Right
   * @type {string}
   */
  @Input() mr: string;

  /**
   * #### h: string
   *  Height
   * @type {string}
   */
  @Input() h: string;

  /**
   * #### w: string
   *  Width
   * @type {string}
   */
  @Input() w: string;

  /**
   * #### url: string
   *  Image src url
   * @type {string}
   */
  @Input() url: string;

  /**
   * #### customClass: string
   *  Css class for component
   * @type {string}
   */
  @Input() customClass: string;

  /**
   * #### isTooltipComponent: boolean
   *  Determines if it's a tooltip icon
   * @type {boolean}
   */
  @Input() isTooltipComponent: boolean;
  public calculatedStyle = '';

  constructor(private sanitizer: DomSanitizer) {
  }

  async ngOnInit() {

    if (this.url && this.url.length == 0) {
      this.url = null;
    }

    if (!this.hasFileExtension) {
      //default is svg
      this.src = this.src + '.svg';
    }

    if (!this.customClass && this.src) {
      if (this.isImage) {
        this.customClass = 'standardImage';
      } else {
        this.customClass = 'standardIcon';
      }
    }

    if (this.isImage) {
      this.customClass += ' image-' + this.specificIconClass;
    } else {
      this.customClass += ' allIcons';
      this.customClass += ' icon-' + this.specificIconClass;
    }


    this.calculateStyle();
  }

  get isImage() {
    const imageExtensionRegex = /\.(jpeg|jpg|gif|png|bmp|tiff|webp)$/i;
    return imageExtensionRegex.test(this.src) || this.customClass?.indexOf('standardImage') > -1 || this.url != null;
  }

  get hasFileExtension(): boolean {
    return /\.[0-9a-z]+$/i.test(this.src);
  }

  get specificIconClass(): string {
    return this.src.replace(/\.[^/.]+$/, '');
  }

  calculateStyle() {
    this.calculatedStyle = '';

    if (this.size) {
      this.calculatedStyle += 'width:' + this.size + 'px;height:' + this.size + 'px;';
    }
    if (this.maxSize) {
      this.calculatedStyle += 'max-width:' + this.maxSize + 'px;max-height:' + this.maxSize + 'px;';
    }

    if (this.h) {
      this.calculatedStyle += 'height:' + this.h + 'px;';
    }
    if (this.w) {
      this.calculatedStyle += 'width:' + this.w + 'px;';
    }

    if (this.mr) {
      this.calculatedStyle += 'margin-right:' + this.mr + 'px;';
    }
    if (this.ml) {
      this.calculatedStyle += 'margin-left:' + this.ml + 'px;';
    }
    if (this.mb) {
      this.calculatedStyle += 'margin-bottom:' + this.mb + 'px;';
    }
    if (this.color) {
      this.calculatedStyle += 'background-color: var(--bcs-' + this.color + ');';
    }

    if (!this.url) {
      if (this.isImage) {
        this.calculatedStyle += 'background-image:url("assets/images/' + this.src + '");';
      } else {
        this.calculatedStyle += ' -webkit-mask-image:url("assets/images/' + this.src + '"); ';
        this.calculatedStyle += ' mask-image:url("assets/images/' + this.src + '"); ';
      }
    }


  }

  getSafeUrl(url: string): SafeUrl {
    if (!url) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  protected readonly toString = toString;
}
