<div class="d-flex" style="position: absolute; top:0; bottom: 0; right: 0; left: 0; min-width: 700px;">
    <sidebar></sidebar>

    <div class="w-100 d-flex flex-column">
        <top-bar></top-bar>
        <div class="h-100 container-fluid main-container">
			<phx-loader class="w-100 h-100" size="big" *ngIf="loading">
			</phx-loader>
            <router-outlet *ngIf="!loading"></router-outlet>
        </div>
    </div>
</div>