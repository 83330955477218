import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoenixWebService } from '@vapp/providers/web.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'request-reviews-modal',
    templateUrl: './request-reviews-modal.component.html',
    styleUrls: ['./request-reviews-modal.component.scss']
})
export class RequestReviewsModalComponent {
	public buttonDisabled: any;
	
	public reviewForm = new FormGroup({
		email: new FormControl(null, Validators.required),
		review: new FormControl(null, Validators.required),
	});

	constructor(public bsModalRef: BsModalRef,public phoenixWebService: PhoenixWebService){ }

	public cancel() {
        this.buttonDisabled = false;
        this.bsModalRef.hide();
    }
	
	public async sendReview(){
		let review = {
			email: this.reviewForm.get('email').value,
			emailText: this.reviewForm.get('review').value
		}
		try{
			await this.phoenixWebService.sendRequestReview(review);
			this.cancel();
		}
		catch(err){
			console.log(err);
		}
	}
}
