import { Component, Input, SimpleChanges } from "@angular/core";
import { JobPosting } from "@vapp/models/job-posting-model";
import { BsModalService } from "ngx-bootstrap/modal";
import { JobPostingDetailsModal } from "../job-posting-details-modal/job-posting-details-modal";

@Component({
    selector: 'recent-job-posting',
    templateUrl: './recent-job-posting.component.html',
    styleUrls: ['./recent-job-posting.component.scss']
})
export class RecentJobPostingComponent {
    @Input() job: JobPosting;
    @Input() bidding: boolean = false;
    @Input() hover?: boolean;
    @Input() select?: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['select']) {
            if (changes['select'].currentValue) {
                this.openJobDetails(this.job);
            }
        }
    }

    constructor(
        private modalService: BsModalService
    ) {

    }

    public openJobDetails(job) {
        const initialState = {
            jobId: job.jobId
        }
        this.modalService.show(JobPostingDetailsModal, {
            class: 'modal-auto-width wide-modal',
            initialState: initialState
        });
    }
}