import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VendorCertificate } from '@vapp/models/vendor-certificate-model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PostingDetailsModalComponent } from '@vapp/modules/common/posting-details-modal/posting-details-modal.component';
import { SharedUtils } from 'phoenix-common';
@Component({
  selector: 'vendor-recent-posting',
  templateUrl: './recent-posting.component.html',
  styleUrls: ['./recent-posting.component.scss']
})
export class RecentPostingComponent implements OnChanges {
  @Input() posting: VendorCertificate;
  @Input() hover?: boolean;
  @Input() select?: boolean;

  constructor(private modalService: BsModalService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['select']) {
      if (changes['select'].currentValue) {
        this.openPostingDetails(this.posting);
      }
    }
  }

  public openPostingDetails(posting) {
    const initialState = {
      posting: posting
    };
    this.modalService.show(PostingDetailsModalComponent, {
      class: 'modal-xl modal-auto-width',
      initialState: initialState
    });
  }

  getBudgetRangeLow() {
    return SharedUtils.formatMoney(this.posting?.budgetRangeLow?.toString() ?? '0')?.displayValue;
  }

  getBudgetRangeHigh() {
    return SharedUtils.formatMoney(this.posting?.budgetRangeHigh?.toString() ?? '0')?.displayValue;
  }

  getPostingClientAddress() {
    const clientLocation: any = this.posting?.clientLocations[0] ?? null;
    if(clientLocation == null) return 'No Address Found';
    return clientLocation?.address + ', ' + clientLocation?.city + ', ' + clientLocation?.state + ' ' + clientLocation?.zip;
  }
}
