import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { AppService } from '@vapp/modules/app/app.service';
import { ComplianceStatus } from '@vapp/utils/Enumerations';
import { DeficiencyStatusID, RegistrationStatus } from 'phoenix-common';

@Component({
    selector: 'client-card',
    templateUrl: './client-card.component.html',
    styleUrls: ['./client-card.component.scss']
})
export class ClientCardComponent {
    @Input('connection') connection: any;
    @Input('client') client: any;
    @Input('selected') selected: boolean = false;
    @Output('onClick') onClick: EventEmitter<{ name?: string, check?: boolean }> = new EventEmitter<{ name?: string, check?: boolean }>();

    public vendor: any;
    public companyName: string;

    faTimesCircle = faTimesCircle;
    faCheckCircle = faCheckCircle;


    public get connectionCompliant(): boolean {
        return this.connection?.accepted && this.connection?.compliance?.registrationStatusEnum == RegistrationStatus.COMPLETE && this.connection?.compliance?.compliantStatus == ComplianceStatus.COMPLIANT;
    }

    constructor(private appService: AppService) {
        this.appService.getLoggedVendor((vendor) => {
            this.vendor = vendor;
            this.companyName = vendor.name;
        });
    }

    onMarkCard() {
        this.onClick.emit(this.connection);
    }

    protected readonly DeficiencyStatusID = DeficiencyStatusID;
}


