import { Component, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@vapp/modules/app/app.service';
import { PhoenixWebService } from '@vapp/providers/web.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RegistrationStatus } from 'phoenix-common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'phx-vertical-steper',
  templateUrl: './phoenix-vertical-steper.component.html',
  styleUrls: ['./phoenix-vertical-steper.component.scss']
})
export class PhoenixVerticalSteperComponent {
  public modalRef: BsModalRef;
  public registrationPortalUrl: string;

  @Input('currentStep') currentStep: number;
  @Input('connection') connection: any;
  @Output('onChange') onChange: EventEmitter<RegistrationStatus> = new EventEmitter<RegistrationStatus>();

  @ViewChild('registrationModal', { static: true }) registrationModalTemplate: TemplateRef<any>;

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.origin == 'https://www.bcscoi.com' && event.data) {
      this.updateRegistrationStatus(event.data);
    }
  }


  public stepper: Array<{ number: number, name: string, active: boolean, done: boolean, display?: string, actions?: Array<any> }> = [
    {
      number: 1,
      name: 'Client Connection Request', active: true, done: false, display: 'Client sent you a Connection Request',
      actions: [
        {
          name: 'Reject',
          icon: 'cancel-white',
          callback: () => this.updateRequestStatus(false),
          type: 'button',
          buttonColor: 'secondary'
        },
        {
          name: 'Accept',
          icon: 'check-white',
          callback: () => this.updateRequestStatus(true),
          type: 'button',
          buttonColor: 'primary'
        }
      ]
    },
    {
      number: 2, name: 'Client Registration', active: false, done: false, display: 'Complete registration process to finish onboarding',
      actions: [
        {
          name: 'Registration Portal',
          icon: 'href',
          callback: () => this.showRegistrationModal()
        }
      ]
    },
    {
      number: 3,
      name: 'Client Compliance',
      active: false,
      done: false,
      display: 'You have outstanding compliance deficiencies on one or more jobs associated with this client',
      actions: [
        {
          name: 'See non-compliant Job(s)',
          icon: 'href',
          callback: () => this.navigateToAssociatedJobs()
        }
      ]
    },
    { number: 4, name: 'Completed', active: false, done: false }
  ];

  constructor(private modalService: BsModalService, private router: Router, private route: ActivatedRoute, private appService: AppService, public phoenixWebService: PhoenixWebService) {
  }

  public async ReloadStepper() {
    this.onChange.emit(RegistrationStatus.REGISTRATION_COMPLETED);
  }


  async updateRequestStatus(accepted: boolean) {
    try {
      await this.phoenixWebService.updateClientConnectionStatus(this.connection.connectionId, accepted);
      if (accepted) {
        this.onChange.emit(RegistrationStatus.CONNECTION_ACCEPTED);
      } else {
        this.onChange.emit(RegistrationStatus.NONE);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async showRegistrationModal() {
    try {
      const { url } = await this.phoenixWebService.getClientRegistrationPortalUrl(this.connection.connectionId);
      this.registrationPortalUrl = url;
      this.modalRef = this.modalService.show(this.registrationModalTemplate, { class: 'modal-xl' });
      const modalSub: Subscription = this.modalService.onHide.subscribe(async (reason: string) => {
        await this.ReloadStepper();
        modalSub.unsubscribe();
      });
    } catch (error) {
      this.appService.showAlertMessage('Failed to get registration portal url.', 'danger');
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async updateRegistrationStatus(certusLink: string) {
    try {
      await this.phoenixWebService.certusRegistration(this.connection.connectionId, certusLink);
      this.onChange.emit(RegistrationStatus.REGISTRATION_COMPLETED);
    } catch (error) {
      console.log(error);
    }
  }

  navigateToAssociatedJobs() {
    this.router.navigate(['../associated-jobs'], { relativeTo: this.route });
  }
}
