<div class="row recent-job-item hand" [class.hover]="hover" (click)="openJobDetails(job)">
	<div class="col-12 col-xl-2 d-flex justify-content-start ">
		<div class="job-posting-image">
      <phx-icon [url]="job?.client?.clientLogo" src="profile-placeholder.png" ></phx-icon>
		</div>
	</div>
	<div class="col-12 col-xl-6 px-2">
        <div><b>{{job?.client?.name}}</b></div>
        <div><b>{{job?.title}}</b></div>
        <div class="job-trades pt-3 pb-3" tooltip="{{ job?.jobTrades | jobTradePipe }}" containerClass="service-tooltip">
            {{job.jobTrades | jobTradePipe}}
        </div>
        <div class="job-description">{{job.description}}</div>
	</div>
	<div class="col-12 col-xl-4">
        <div class="row" *ngIf="bidding">
            <div class="col-12 d-flex justify-content-end">
                <phx-button data-id="vendorRecentJobPostingBiddingBtn" text="Bidding" color="aquamarine" size="extra-small" class="mr-2 fs-13">
                </phx-button>
            </div>
        </div>
        <div class="row" style="height: 103px;">
            <div class="col-12 justify-content-md-end location-column">
                <div>
                    {{job.address}}
                </div>
                <div class="mt-2">
                    <div>Project Starts: {{job.jobStartDate | date}}</div>
                    <div>Project Ends: {{job.jobEndDate | date}}</div>
                    <div>Posting Expires: {{job.expirationDate | date}}</div>
                </div>
            </div>
        </div>
    </div>
</div>





<!-- <div >
    fdfsds
</div>
<div class="d-flex">
    {{job.address}}
</div>
<div>Project Start: {{job.jobStartDate | date}}</div>
<div>Posting Expires: {{job.expirationDate | date}}</div> -->
