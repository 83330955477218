export class GlobalConstants {
  public static readonly ALLOWED_FILE_TYPES = ['text/plain', 'image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
  public static readonly CLIENT_SUPPORT_EMAIL = 'customersupport@getbcs.com';
  public static readonly TOS_URL = 'https://www.businesscredentialingservices.com/legal';
  public static readonly PRIVACY_POLICY_URL = 'https://www.businesscredentialingservices.com/privacy';
  public static readonly VENDOR_LOGIN_URL = 'https://vendor.bcsaudit.com/';
  public static readonly BLACKLISTED_DOMAINS = [
    'mycoitracking.com',
    'getjones.com',
    'netvendor.com',
    'ebix.com',
    'entrata.com',
    'billyforinsurance.com',
    'evidentid.com',
    'jdidata.com',
    'kernuo.com'
  ];
  public static readonly INVITE_DEFAULT_NOTE = `Hi there,

I'd like to add you to my vendors and use your services. Please accept this invitation so you can submit your evidence of insurance for compliance purposes.

Thanks`;
  public static readonly NEW_ADDITIONAL_JOB_NOTE = `Hi there,

I'd like to assign a new job for your services at the following locations: ({-Locations Name-}). You'll have to provide a new set of compliance documents to be approved for this job.

Thanks`;

  public static readonly VENDOR_CREDENTIALS_OBJ = {
    'NY_CERTIFIED': {
      name: 'BCS Certified for New York',
      icon_class: 'img-BCS'
    },
    'VERIFIED_LICENSES': {
      name: 'Verified - Licenses',
      icon_class: 'img-licenses'
    },
    'SCREENED_FINANCIAL': {
      name: 'Screened - Financial',
      icon_class: 'img-financial'
    },
    'SCREENED_BACKGROUND_CHECK': {
      name: 'Screened - Background Check',
      icon_class: 'img-backgroundCheck'
    }
  };

  public static readonly US_CERTUS_STATE_VALUES: any[] = [
    { name: 'ALABAMA' },
    { name: 'ALASKA' },
    { name: 'ARIZONA' },
    { name: 'ARKANSAS' },
    { name: 'CALIFORNIA' },
    { name: 'COLORADO' },
    { name: 'CONNECTICUT' },
    { name: 'DELAWARE' },
    { name: 'FLORIDA' },
    { name: 'GEORGIA' },
    { name: 'HAWAII' },
    { name: 'IDAHO' },
    { name: 'ILLINOIS' },
    { name: 'INDIANA' },
    { name: 'IOWA' },
    { name: 'KANSAS' },
    { name: 'KENTUCKY' },
    { name: 'LOUISIANA' },
    { name: 'MAINE' },
    { name: 'MARYLAND' },
    { name: 'MASSACHUSETTS' },
    { name: 'MICHIGAN' },
    { name: 'MINNESOTA' },
    { name: 'MISSISSIPPI' },
    { name: 'MISSOURI' },
    { name: 'MONTANA' },
    { name: 'NEBRASKA' },
    { name: 'NEVADA' },
    { name: 'NEW HAMPSHIRE' },
    { name: 'NEW JERSEY' },
    { name: 'NEW MEXICO' },
    { name: 'NEW YORK' },
    { name: 'NORTH CAROLINA' },
    { name: 'NORTH DAKOTA' },
    { name: 'OHIO' },
    { name: 'OKLAHOMA' },
    { name: 'OREGON' },
    { name: 'PENNSYLVANIA' },
    { name: 'RHODE ISLAND' },
    { name: 'SOUTH CAROLINA' },
    { name: 'SOUTH DAKOTA' },
    { name: 'TENNESSEE' },
    { name: 'TEXAS' },
    { name: 'UTAH' },
    { name: 'VERMONT' },
    { name: 'VIRGINIA' },
    { name: 'WASHINGTON' },
    { name: 'WEST VIRGINIA' },
    { name: 'WISCONSIN' },
    { name: 'WYOMING' }
  ];
}

export class CustomPropertyTypes {
  public static readonly DROPDOWN_TYPES = [
    {
      name: 'Text Box',
      type: 1
    },
    {
      name: 'Text Area',
      type: 2
    },
    {
      name: 'Check Box',
      type: 3
    },
    {
      name: 'Date',
      type: 4
    },
    {
      name: 'Dropdown Single Select',
      type: 5
    },
    {
      name: 'Dropdown Multi-Select',
      type: 6
    }
  ];
}

export enum CustomPropertyTypesEnum {
  TEXT_BOX = 1,
  TEXT_AREA = 2,
  CHECK_BOX = 3,
  DATE = 4,
  DROPDOWN_SINGLE_SELECT = 5,
  DROPDOWN_MULTI_SELECT = 6,
  DOCUMENT
}

export class ClientIndustries {
  public static readonly INDUSTRIES_MAP = {
    COMMERCIAL_REAL_ESTATE: {
      name: 'Commercial Real Estate',
      icon: 'Commercial_Real_Estate.png'
    },
    MULTIFAMILY: {
      name: 'Multifamily',
      icon: 'Multifamily.png'
    },
    AFFORDABLE_HOUSING: {
      name: 'Affordable Housing',
      icon: 'Affordable_Housing.png'
    },
    CONSTRUCTION: {
      name: 'Construction',
      icon: 'Construction.png'
    },
    HOSPITALITY: {
      name: 'Hospitality',
      icon: 'Hospitality.png'
    },
    RETAIL: {
      name: 'Retail',
      icon: 'Retail.png'
    },
    FRANCHISE_MANAGEMENT: {
      name: 'Franchise Management',
      icon: 'Franchise.png'
    },
    GOVERNMENT: {
      name: 'Government',
      icon: 'Government.png'
    },
    LOGISTICS: {
      name: 'Logistics',
      icon: 'Logisitics.png'
    },
    OTHER: {
      name: 'Other',
      icon: 'Other.png'
    }
  };
  public static readonly INDUSTRIES = Object.keys(ClientIndustries.INDUSTRIES_MAP).map((val, index) => ({ id: index, ...ClientIndustries.INDUSTRIES_MAP[val] }));
}

export class TrackingEntities {
  public static readonly TRACKING_ENTITIES_MAP = {
    VENDORS: {
      name: 'Vendors / Subcontractors',
      icon: 'Vendor.png'
    },
    TENANTS: {
      name: 'Tenants / Franchisees / Borrowers',
      icon: 'Tenant.png'
    }
  };
  public static readonly TRACKING_ENTITIES = Object.keys(TrackingEntities.TRACKING_ENTITIES_MAP).map(val => TrackingEntities.TRACKING_ENTITIES_MAP[val]);
}

export class ClientUserRoles {
  public static readonly CLIENT_USER_ROLES_MAP = {
    RISK_MANAGEMENT: {
      name: 'Risk Management / Insurance'
    },
    FACILITY: {
      name: 'Facility / Property Management'
    },
    ACCOUNTING: {
      name: 'Accounting / Finance'
    },
    CONSTRUCTION_PM: {
      name: 'Construction Project Management'
    },
    IT: {
      name: 'IT'
    },
    LEGAL: {
      name: 'Legal'
    }
  };
  public static readonly CLIENT_USER_ROLES = Object.keys(ClientUserRoles.CLIENT_USER_ROLES_MAP).map(val => ClientUserRoles.CLIENT_USER_ROLES_MAP[val]);
}
