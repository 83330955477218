import { IBaseModel } from './base-model';

export class User implements IBaseModel {

    public id: string;
    public email: string;
    public name: string;
    public cognitoId: string;
    public vendorId: string;
    public avatarUrl: string;

    public fromJSON(): User {

        return this;
    }

    public toJSON(): any {
        return {};
    }
}