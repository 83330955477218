import { IBaseModel } from './base-model';
import { Location } from '../../../phoenix-common/src/lib/models/location-model';

export class JobPosting implements IBaseModel {
    public jobId: string;
    public clientId: string;
    public address: string;
    public vendorId: string;
    public title: string;
    public description: string;
    public createdAt: Date;
    public completedAt: Date;
    public updatedAt: Date;
    public budgetRangeHigh: number;
    public budgetRangeLow: number;
    public distance: number;
    public expirationDate: string;
    public jobStartDate: Date;
    public jobEndDate: Date;
    public clientLocationId: string;
    public clientLocations: Array<Location> = [];
    public jobTrades: Array<any> = [];
    public jobTypes: Array<any> = [];
    public bidding: boolean;
    public conversationId: number;
    public isInvitation: boolean = false;
    public invitationId: number;
    public compliance: any;
    public client: any;
    public jobsStatesResponse: any;
    public lat: number;
    public long: number;

    toJSON() {
        throw new Error('Method not implemented.');
    }

    public fromJSON(json: any): JobPosting {
        this.jobId = json["jobId"];
        this.clientId = json["clientId"];
        this.address = json["address"]
        this.vendorId = json["vendorId"];
        this.title = json["title"];
        this.description = json["description"];
        this.createdAt = json["createdAt"] ? new Date(json["createdAt"]) : null;
        this.completedAt = json["completedAt"] ? new Date(json["completedAt"]) : null;
        this.updatedAt = json["updatedAt"] ? new Date(json["updatedAt"]) : null;
        this.budgetRangeHigh = json["budgetRangeHigh"];
        this.budgetRangeLow = json["budgetRangeLow"];
        this.distance = json["distance"];
        this.expirationDate = json["expirationDate"];
        this.jobStartDate = json["jobStartDate"];
        this.jobEndDate = json["jobEndDate"];
        this.bidding = json["bidding"];
        this.conversationId = json["conversationId"];
        this.isInvitation = json["isInvitation"];
        this.invitationId = json["invitationId"];
        this.compliance = json["compliance"];
        this.client = json["client"];
        this.jobsStatesResponse = json["jobsStatesResponse"];
        this.lat = json["lat"];
        this.long = json["long"]

        if (json["jobTrades"]) {
            json["jobTrades"].forEach(el => {
                this.jobTrades.push(el);
            });
        }

        if (json["jobTypes"]) {
            json["jobTypes"].forEach(el => {
                this.jobTypes.push(el["jobTypeId"]);
            });
        }

        if (json["jobLocations"]) {
            json["jobLocations"].forEach(el => {
                this.clientLocations.push(new Location().fromJSON(el["clientLocation"]));
            });
        }

        return this;
    }

    public listFromJson(json: any): Array<JobPosting> {
        return json == null ? new Array<JobPosting>() : json.map((value) => new JobPosting().fromJSON(value));
    }

}
