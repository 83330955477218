<div class="card-container m-3 p-3" [class.active]="selected" (click)="onMarkCard()">
  <div class="status-container">
    <div class="status-icon">
      <phx-icon customClass="standardImage" [src]="connectionCompliant ? 'compliant' : 'non-compliant'"></phx-icon>
    </div>
  </div>
  <div class="row justify-content-center align-items-center d-flex">
    <div class="col-12">
      <div class="header-logo"
           [style.background-image]="'url(' + (connection ? connection?.client?.config[0].logoUrl : client.logoUrl ? client?.logoUrl : '') + ')' | safe: 'style'">
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="text-center p-1 client-name">
      {{connection ? connection?.client?.name : client?.name}}
    </div>
  </div>
</div>
