import { IBaseModel } from './base-model';
export class JobPostingAlert implements IBaseModel {
    public alertId: number;
    public vendorId: number;
    public lat: number;
    public lng: number;
    public radius: number = 0;
    public startDate: Date = new Date(Date.now());
    public endDate: Date = new Date(Date.now());
    public location: string;
    public enabled: boolean;

    public trades: Array<any> = [];
    public jobTypes: Array<any> = [];

    toJSON() {
        throw new Error('Method not implemented.');
    }

    public fromJSON(json: any): JobPostingAlert {
        this.alertId = json["alertId"];
        this.vendorId = json["vendorId"];
        this.lat = json["lat"]
        this.lng = json["lng"];
        this.radius = json["radius"];
        this.startDate = json["startDate"] ? new Date(json["startDate"]) : null;
        this.endDate = json["endDate"] ? new Date(json["endDate"]) : null;
        this.location = json["location"];
        this.enabled = json["enabled"];

        if (json["trades"]) {
            json["trades"].forEach(el => {
                this.trades.push(el.id);
            });
        }

        if (json["jobTypes"]) {
            json["jobTypes"].forEach(el => {
                this.jobTypes.push(el.id);
            });
        }

        return this;
    }
}