<div class="step" *ngFor="let step of stepper; let i = index;"
	[class.active]="i == currentStep && currentStep != stepper?.length - 1"
	[class.completed]="i < currentStep || currentStep == stepper?.length - 1">
	<div class="v-stepper hand">
		<div class="circle">{{step.number}}</div>
		<div class="line"></div>
	</div>

	<div class="content">
		<div class="content-header hand">{{step.name}}</div>
		<div class="content-body ml-3" *ngIf="i == currentStep">
			<div class="py-4">{{step.display}}</div>
			<div class="d-flex hand">
				<ng-container *ngFor="let action of step.actions">
					<phx-button data-id="vendorPhoenicVerticalSteperBtn" *ngIf="action.type=='button'" btnIcon="{{action.icon}}"  [text]="action.name" [color]="action.buttonColor"
						size="medium" class="ml-3" style="width: 100px;" (click)="action.callback()">
					</phx-button>
					<div *ngIf="!action.type" (click)="action.callback()" class="link-button">{{action.name}}</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<ng-template #registrationModal>
	<div class="modal-body text-center">
		<iframe #regIframe [src]="registrationPortalUrl | safe:'resourceUrl'"
			style="position: relative;width: 100%;min-height: 85vh;" frameborder="0"></iframe>
	</div>
</ng-template>
