import { Component, Input } from '@angular/core';

@Component({
	selector: 'phx-progressbar',
	templateUrl: './phoenix-progressbar.component.html',
	styleUrls: ['./phoenix-progressbar.component.scss']
})
export class PhoenixProgressBarComponent {
	@Input('value') value: number = 0;
	@Input('showValue') showValue: boolean = false;

}
