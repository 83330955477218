<div *ngIf="!hasProgressStatusId" class="w-100 h-100 text-center">
  <label class="progress-status">{{ getStatusType(dataRow) }}</label>
</div>

<div *ngIf="hasProgressStatusId">
  <div class="w-100 h-100 text-center">
    <label class="progress-status">
      <ng-container *ngIf="dataRow.isPending" >
        Requested Registration
      </ng-container>
      <ng-container *ngIf="!dataRow.isActive && !dataRow.isPending" >
        N/A (Inactive)
      </ng-container>
      <ng-container *ngIf="dataRow.isActive && !dataRow.isPending" >
        <ng-container [ngSwitch]="dataRow.progressStatusId" >
          <ng-container *ngSwitchCase="7">
            Requesting Renewal
          </ng-container>
          <ng-container *ngSwitchCase="6">
            {{ dataRow?.backToClient ?? 'Unresponsive' }}
          </ng-container>
          <ng-container *ngSwitchCase="5">
            Complete
          </ng-container>
          <ng-container *ngSwitchCase="4">
            Requesting Corrections
          </ng-container>
          <ng-container *ngSwitchCase="3">
            Pending Review
          </ng-container>
          <ng-container *ngSwitchCase="2">
            Request Sent
          </ng-container>
          <ng-container *ngSwitchCase="1">
            No Action Performed
          </ng-container>
          <ng-container *ngSwitchDefault >
            No Action Performed
          </ng-container>
        </ng-container>
      </ng-container>
    </label>
  </div>


</div>
