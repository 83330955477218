<nav class="phx-topbar">

  <div class="top-section">
    <div class="top-left" *ngIf="appService.topBarLeftContent">
      <div class="align-items-start justify-content-start">
        <ng-container *ngTemplateOutlet="appService.topBarLeftContent"></ng-container>
      </div>
    </div>
    <div class="top-middle" *ngIf="appService.topBarCenterContent && !appService.topBarTabContent">
      <ng-container *ngTemplateOutlet="appService.topBarCenterContent"></ng-container>
    </div>
    <div class="top-right">
      <li style="position: absolute;top:30px;right:20px;" class="menu-item" dropdown>

        <a (click)="false" dropdownToggle aria-controls="basic-link-dropdown" class="profile-icon-container">
          <phx-icon src="avatar-placeholder.png" size="35" extraClass="circle"></phx-icon>
        </a>
        <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
          <li>
            <a class="dropdown-item" routerLink="me">My Settings</a>
          </li>
          <li>
            <a class="dropdown-item" target="_blank" href="https://help.businesscredentialingservices.com/">Help</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </li>
        </ul>
      </li>
    </div>
  </div>
  <div class="bottom-section" *ngIf="appService.topBarTabContent">
    <ng-container *ngTemplateOutlet="appService.topBarTabContent"></ng-container>

    <div class="bottom-middle-section" *ngIf="appService.topBarBottomMiddleContent">
      <ng-container *ngTemplateOutlet="appService.topBarBottomMiddleContent"></ng-container>
    </div>

  </div>

</nav>
