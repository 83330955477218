import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { VendorWebappCommonModule } from '../common/vendor-webapp-common.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { VendorSessionComponent } from '@vapp/modules/app/vendor-session/vendor-session.component';
import { ENVIRONMENT } from '../../../../phoenix-common/src/lib/tokens/environment.token';
import { environment } from '@vapp/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    FooterComponent,
    SidebarComponent,
    MainComponent,
    VendorSessionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    VendorWebappCommonModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule
  ],
  exports: [
    TopBarComponent,
    FooterComponent,
    SidebarComponent
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
