import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PhoenixWebService } from '@capp/providers/web.service';
import { ColumnType, IColumnStruct } from 'phoenix-common';

interface InsuranceRequirementCategoryData {
  insuranceRequirementCategoryName: string;
  insuranceRequirementCategoryDescription: string;
  policyTemplateData: PolicyTemplateData[];
  additionalTerms: AdditionalTerm[];
  endorsementPolicies: EndorsementData[];
  documents: Document[];
}

interface PolicyTemplateData {
  policyName: string;
  limitNames: string[];
  limitAmounts: number[];
}

interface AdditionalTerm {
  termName: string;
}

interface Document {
  documentTypeName: string;
  required: boolean;
}

interface EndorsementData {
  policyName: string;
  endorsementName: string;
}

@Component({
  selector: 'view-requirements-modal',
  templateUrl: './view-requirements-modal.component.html',
  styleUrls: ['./view-requirements-modal.component.scss']
})
export class ViewRequirementsModalComponent implements OnInit {
  isVendor: boolean;
  requirementId: number;
  loaded: boolean = false;
  insuranceRequirementCategoryName: string;
  insuranceRequirementCategoryDescription: string;
  protected requirement: any;
  protected requirementPolicies: Array<PolicyTemplateData> = [];
  protected additionalTerms: AdditionalTerm[] = [];
  protected documents: Document[] = [];
  protected endorsementPolicies: Array<EndorsementData> = [];

  protected requirementsColumns: Array<IColumnStruct> = [
    {
      title: 'Policies',
      variable: 'policyName'
    },
    {
      title: 'Limits',
      variable: 'limitNames',
      type: ColumnType.Rows
    },
    {
      title: 'Limit Amounts',
      variable: 'limitAmounts',
      type: ColumnType.RowsMoney
    }
  ];

  protected additionalTermsColumns: Array<IColumnStruct> = [
    {
      title: 'Additional Terms',
      variable: 'termName'
    }
  ];

  protected endorsementsColumns: Array<IColumnStruct> = [
    {
      title: 'Policy',
      variable: 'policyName'
    },
    {
      title: 'Endorsement',
      variable: 'endorsementName'
    }
  ];

  protected documentsColumns: Array<IColumnStruct> = [
    {
      title: 'Document',
      variable: 'documentTypeName'
    },
    {
      title: 'Required',
      variable: 'required',
      type: ColumnType.CheckBox
    }
  ];

  constructor(
    protected phoenixWebService: PhoenixWebService,
    protected bsModalRef: BsModalRef
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.init();
  }

  protected async init() {
    const result: InsuranceRequirementCategoryData = await this.phoenixWebService.getRequirementDataByReqId(this.requirementId, this.isVendor);
    this.insuranceRequirementCategoryName = result?.insuranceRequirementCategoryName;
    this.insuranceRequirementCategoryDescription = result?.insuranceRequirementCategoryDescription;
    this.requirementPolicies = result?.policyTemplateData;
    this.additionalTerms = result?.additionalTerms ?? [];
    this.endorsementPolicies = result?.endorsementPolicies ?? [];
    this.documents = result?.documents ?? [];
    this.loaded = true;
  }
}
