<div class="session-container">
  <div class="title">Session Information</div>
  <div class="user-id"><b>User ID:</b> {{ decodedToken.sub }}</div>
  <div class="role"><b>Role:</b> {{ decodedToken.role }}</div>
  <div class="user-email"><b>Email:</b> {{ decodedToken.email }}</div>
  <div class="email-verified">
    <b>Email Verified:</b> {{ decodedToken.email_verified }}
  </div>
  <div class="client-id"><b>Vendor Id:</b> {{ decodedToken.vendorId }}</div>
  <div class="token"><b>Id Token:</b> {{ decodedToken.token }}</div>
</div>
