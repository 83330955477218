import { Injectable, TemplateRef } from '@angular/core';
import { AlertModel } from '@vapp/models/alert-model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PhoenixChatService } from 'phoenix-common';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { User as TwilioUser } from '@twilio/conversations';
import { User } from '../../models/user-model';
import { GlobalConstants } from '@vapp/utils/Constants';

@Injectable({ providedIn: 'root' })
export class AppService {
  public token: string = null;
  public topBarLeftContent: TemplateRef<any>;
  public topBarCenterContent: TemplateRef<any>;
  public topBarBottomMiddleContent: TemplateRef<any>;
  public topBarTabContent: TemplateRef<any>;
  public loggedUserObject: User;
  public loggedVendorObject: any;
  public loggedUserTokenStr: string;
  public jobTradesArr: Array<any>;
  public jobTradesMaps = {};
  public jobTypesArr: Array<any>;
  public jobTypesMap = {};
  public globalConstants = GlobalConstants;

  // Confirm Modal
  public modalPromiseResolve: any = null;
  public modalRef: BsModalRef = null;
  public modalConfirmTemplate: any;
  public modalConfirmTitleSubject: Subject<string> = new Subject();

  // Alert
  public alertMessageSubject: Subject<AlertModel> = new Subject();

  // Observable boolean sources
  public loggedUserSource = new BehaviorSubject<any>(null);
  public loggedVendorSource = new Subject<any>();
  public loggedUserTokenSource = new Subject<any>();
  public jobTradesSource = new Subject<any>();
  public jobTypesSource = new Subject<any>();
  public currentConversationSource = new ReplaySubject<any>(1);

  // Observable boolean streams
  public loggedUser = this.loggedUserSource.asObservable();
  public loggedUserToken = this.loggedUserTokenSource.asObservable();
  public loggedVendor = this.loggedVendorSource.asObservable();
  public jobTrades = this.jobTradesSource.asObservable();
  public jobTypes = this.jobTypesSource.asObservable();

  public twilioUser: TwilioUser;

  constructor(private modalService: BsModalService, private phoenixChatService: PhoenixChatService) {
  }

  setloggedVendor(loggedVendor: any) {
    this.loggedVendorObject = loggedVendor;
    this.loggedVendorSource.next(this.loggedVendorObject);
    this.phoenixChatService.setSelfIdentity({
      'name': this.loggedVendorObject.name,
      'logoUrl': this.loggedVendorObject?.profile[0]?.logoUrl
    });
  }

  getLoggedVendor(callback: (vendor: any) => void = null): any {
    if (callback) {
      if (this.loggedVendorObject) {
        callback(this.loggedVendorObject);
      } else {
        this.loggedVendor.subscribe(callback);
      }

      return;
    }
    return this.loggedVendorObject;
  }

  setLoggedInUser(loggedUser: User) {
    this.loggedUserObject = loggedUser;
    if (this.loggedUserObject && this.loggedUserObject.cognitoId) {
      this.phoenixChatService.setSelfIdentity({ 'userId': this.loggedUserObject['cognitoId'] });
    }
    this.loggedUserSource.next(this.loggedUserObject);
  }

  getLoggedUser(callback: (user: User) => void = null): any {
    if (callback) {
      if (this.loggedUserObject) {
        callback(this.loggedUserObject);
      } else {
        this.loggedUser.subscribe(callback);
      }

      return;
    }

    return this.loggedUserObject;
  }

  logout() {
    this.loggedUserObject = null;
    this.loggedUserSource.next(this.loggedUserObject);
    this.loggedVendorObject = null;
    this.loggedVendorSource.next(this.loggedVendorObject);
  }

  setJobTypes(jobTypes: Array<any>) {
    this.jobTypesArr = jobTypes;

    for (let jt of this.jobTypesArr) {
      this.jobTypesMap[jt['id']] = jt['name'];
    }

    this.jobTypesSource.next(this.jobTypesArr);
  }

  getJobTypes(callback: (jobTypes: Array<any>) => void = null): any {
    if (callback) {
      if (this.jobTypesArr) {
        callback(this.jobTypesArr);
      } else {
        this.jobTypes.subscribe(callback);
      }

      return;
    }

    return this.jobTypesArr;
  }

  setJobTrades(jobTrades: Array<any>) {
    this.jobTradesArr = jobTrades;

    for (let jt of this.jobTradesArr) {
      this.jobTradesMaps[jt['id']] = jt['name'];
    }

    this.jobTradesSource.next(this.jobTradesArr);
  }

  getJobTrades(callback: (jobTrades: Array<any>) => void = null): any {
    if (callback) {
      if (this.jobTradesArr) {
        callback(this.jobTradesArr);
      } else {
        this.jobTrades.subscribe(callback);
      }

      return;
    }

    return this.jobTradesArr;
  }

  setJWTToken(token: string) {
    this.loggedUserTokenStr = token;
    localStorage.setItem('authToken', token);
    this.loggedUserTokenSource.next(this.loggedUserTokenStr);
  }

  getJWTToken(): string {
    return this.loggedUserTokenStr;
  }

  // Alert
  public showAlertMessage(title: string, type: string) {
    this.alertMessageSubject.next({ title, type });
  }


  // Confirm Modal
  public async confirmModal(title: string): Promise<boolean> {
    const res = new Promise<boolean>((resolve) => {
      this.modalPromiseResolve = resolve;
      this.modalConfirmTitleSubject.next(title);
      this.modalRef = this.modalService.show(this.modalConfirmTemplate, {
        class: 'modal-lm',
        backdrop: 'static',
        ignoreBackdropClick: true
      });
      const modalHideSub = this.modalService.onHide.subscribe((reason) => {
        if (reason) {
          resolve(false);
        }
        modalHideSub.unsubscribe();
      });
    });
    return res;
  }


  //Edit Pic Modal
  public async openEditPic(title: string): Promise<boolean> {
    const res = new Promise<boolean>((resolve) => {
      this.modalPromiseResolve = resolve;
      this.modalConfirmTitleSubject.next(title);
      this.modalRef = this.modalService.show(this.modalConfirmTemplate, {
        class: 'modal-lm',
        backdrop: 'static',
        ignoreBackdropClick: true
      });
      const modalHideSub = this.modalService.onHide.subscribe((reason) => {
        if (reason) {
          resolve(false);
        }
        modalHideSub.unsubscribe();
      });
    });
    return res;
  }

  // Modal Confirm func
  public confirm(): void {
    if (this.modalPromiseResolve) {
      this.modalPromiseResolve(true);
      this.modalRef.hide();
    }
  }

  // Modal Decline func
  public decline(): void {
    if (this.modalPromiseResolve) {
      this.modalPromiseResolve(false);
      this.modalRef.hide();
    }
  }
}
