import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUser, Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { SignUpParams } from '@aws-amplify/auth/lib-esm/types';
import { User } from '@vapp/models/user-model';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AppService } from '../app/app.service';
import { ClientTokenInfo } from '../../../../phoenix-common/src/lib/shared/models/client-token-info';
import { JwtHelperService } from '@auth0/angular-jwt';
import { VendorTokenInfo } from '../../../../phoenix-common/src/lib/shared/models/vendor-token-info';

const jwtHelper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthService {
  public loggedInUser: CognitoUser;
  public signedIn = false;
  public refreshInterval: any;

  constructor(
    private appService: AppService,
    private router: Router) {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn' || payload.event === 'signOut') {
        this.handleAuthStateChange().then();
      }
    });

    this.checkCurrentSession();
  }


  async handleAuthStateChange() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.signedIn = !!user;
      this.loggedInUser = user;

      // Setup or clear refresh interval for session
      if (this.refreshInterval) {
        window.clearInterval(this.refreshInterval);
      }

      if (this.signedIn) {
        this.refreshInterval = setInterval(async () => {
          await this.refreshSession();
        }, 1000 * 60 * 5);
      } else {
        this.appService.setLoggedInUser(null);
      }
    } catch (error) {
      this.signedIn = false;
      this.loggedInUser = null;
      this.appService.setLoggedInUser(null);
    }
  }

  public checkCurrentSession(): void {
    Auth.currentSession().then(currentSession => {
      const accessToken = currentSession.getIdToken();
      const token = accessToken.getJwtToken();
      localStorage.setItem('authToken', token);
      const user: User = new User();
      const jwtDecoded = accessToken.payload;
      user.email = jwtDecoded.email;
      user.name = jwtDecoded.name;
      user.cognitoId = jwtDecoded['cognito:username'];
      user.vendorId = jwtDecoded.vendorId;
      this.appService.setJWTToken(token);
      this.appService.setLoggedInUser(user);
    });
  }

  public async refreshSession(): Promise<void> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession: CognitoUserSession = await Auth.currentSession();

    cognitoUser.refreshSession(currentSession.getRefreshToken(), (_: any, session: any) => {
      if (session) {
        const { idToken } = session;
        const user: User = new User();
        const jwtDecoded = idToken.payload;
        user.email = jwtDecoded.email;
        user.name = jwtDecoded.name;
        user.id = jwtDecoded.clientId;
        user.cognitoId = jwtDecoded['cognito:username'];
        this.appService.setJWTToken(idToken?.jwtToken);
        this.appService.setLoggedInUser(user);
      }
    });
  }

  public async login(username: string, password: string): Promise<any> {
    const session = await Auth.signIn({ username: username.toLowerCase(), password });
    this.checkCurrentSession();
    return session;
  }

  public async signup(registrationToken: string,
                      initialCompanyName: string,
                      taxId: string,
                      firstName: string,
                      lastName: string,
                      email: string,
                      password: string): Promise<any> {

    const signupParams: SignUpParams = {
      username: email.toLowerCase(),
      password,
      attributes: {
        name: firstName + ' ' + lastName,
        'custom:InitialCompanyName': initialCompanyName,
        'custom:RegistrationToken': registrationToken,
        'custom:TaxId': taxId
      }

    };

    return await Auth.signUp(signupParams);
  }

  public async logout(shouldReturnToUrl: boolean = false): Promise<any> {
    this.appService.logout();
    if (shouldReturnToUrl) {
      const redirectUrl = window.location.href;
      await this.router.navigate(['login'], {
        queryParams: {
          return: redirectUrl ? redirectUrl : ''
        }
      });
    } else {
      await this.router.navigate(['login']);
    }

    return await Auth.signOut();
  }

  public getDecodedToken(): VendorTokenInfo {
    const idToken = this.appService.getJWTToken();
    var tokenInfo = jwtHelper.decodeToken<VendorTokenInfo>(idToken);
    tokenInfo.token = idToken;
    return tokenInfo;
  }
}
