import { Injectable } from '@angular/core';

declare const aptrinsic: any;

@Injectable({ providedIn: 'root' })
export class GainsightService {
    public identify(id: string, email: string, name: string, vendorId: number, vendorName: string): void {
        aptrinsic('identify',
            {
                id,
                email,
                'signedUpAt': Date.now(),
                'firstName': name
            },
            {
                id: vendorId,
                name: vendorName
            }
        );
    }

    public logout(): void {
        aptrinsic('reset');
    }
}