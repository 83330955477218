import { IBaseModel } from './base-model';

export class ClientContact implements IBaseModel {

    public clientContactId: number;
    public clientId: number;
    public name: string;
    public title: string;
    public phone: string;
    public email: string;

    public fromJSON(json: any): ClientContact {
        if (json) {
            this.clientContactId = json["clientContactId"];
            this.clientId = json["clientId"];
            this.name = json["name"];
            this.title = json["title"];
            this.phone = json["phone"];
            this.email = json["email"];
        }
        return this;
    }

    static listFromJson(json: any): Array<ClientContact> {
        return json == null ? new Array<ClientContact>() : json.map((value) => new ClientContact().fromJSON(value));
    }

    public toJSON(): any {
        return {
            clientContactId: this.clientContactId || null,
            clientId: this.clientId || null,
            name: this.name || null,
            title: this.title || null,
            phone: this.phone || null,
            email: this.email || null,
        };
    }
}


