import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { User } from '@vapp/models/user-model';
import { AuthService } from '@vapp/modules/auth/auth-service';
import { PhoenixWebService } from '@vapp/providers/web.service';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
  public loggedInUser: User;
  public client;
  public image;

  constructor(public appService: AppService,
              private authService: AuthService,
              private phoenixWebService: PhoenixWebService) {

    this.appService.loggedUserSource.subscribe((user) => {
      this.loggedInUser = user;
      this.getVendorUser();
    });

    this.appService.getLoggedVendor((vendor) => {
      this.client = vendor;
    });
  }

  public async getVendorUser() {
    if (this.loggedInUser?.cognitoId) {
      const vendorUser = await this.phoenixWebService.getVendorUsersById(this.loggedInUser.cognitoId);
      if (vendorUser.avatarUrl) {
        this.loggedInUser.avatarUrl = vendorUser.avatarUrl;
      }
    }
  }

  public async logout() {
    this.authService.logout();
  }

  public helpClick() {
  }

  public settings() {
  }

  public classicBCS() {
  }

  public profile() {
  }
}
