import { Component, TemplateRef } from '@angular/core';
import { PhoenixWebService } from '@vapp/providers/web.service';
import { AppService } from '../app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public vendor: any;
  public currentClientLogoClass: string = null;
  public modalRef: BsModalRef;

  public vendorName: string;
  public image;
  public loading: boolean = false;
  public faEdit = faEdit;

  constructor(private phoenixWebService: PhoenixWebService,
              private appService: AppService,
              private modalService: BsModalService) {

    this.appService.getLoggedVendor((vendor) => {
      if (vendor) {
        this.vendor = vendor;
        this.vendorName = vendor.name;
      }
    });

  }

  public openMessages() {

  }

  public showUpdateVendorInfoModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal);
    let sub = this.modalService.onHide.subscribe(async () => {
      let vendor = await this.phoenixWebService.getLoggedVendorInfo();
      this.appService.setloggedVendor(vendor);
      sub.unsubscribe();
    });
  }

  public onFileUpload(file: File) {
    if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
      this.appService.showAlertMessage('Invalid media format! Supported media formats: jpg,jpeg,png', 'danger');
      file = null;
    } else {
      this.image = file;
    }
  }

  public async saveVendorInfo() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    let formData: any = new FormData();
    formData.append('vendorName', this.vendorName);
    formData.append('vendorLogo', this.image);

    try {
      await this.phoenixWebService.updateVendorInfo(formData);
    } catch (err) {
      this.appService.showAlertMessage('Something went wrong!', 'danger');
    } finally {
      this.loading = false;
      this.modalRef.hide();
    }
  }
}
