import { IBaseModel } from './base-model';
import { Location } from 'phoenix-common';

export class VendorCertificate implements IBaseModel {
  public certificateId: number;
  public postingId: number;
  public clientId: number;
  public companyId: number;
  public address: string;
  public vendorId: number;
  public title: string;
  public description: string;
  public createdAt: Date;
  public completedAt: Date;
  public updatedAt: Date;
  public budgetRangeHigh: number;
  public budgetRangeLow: number;
  public distance: number;
  public expirationDate: string;
  public startDate: Date;
  public endDate: Date;
  public clientLocations: Array<Location> = [];
  public trades: Array<any> = [];
  public types: Array<any> = [];
  public isBidding: boolean;
  public isAwarded: boolean;
  public conversationId: number;
  public client: any;
  public lat: number;
  public long: number;
  public requirementCategoryId: number;
  public attachments: {fileName: string, attachmentUrl: string}[];

  public fromJSON(json: any): VendorCertificate {
    this.certificateId = json['certificateId'];
    this.clientId = json['clientId'];
    this.companyId = json['companyId'];
    this.address = json['address'];
    this.vendorId = json['vendorId'];
    this.title = json['title'];
    this.description = json['description'];
    this.createdAt = json['createdAt'] ? new Date(json['createdAt']) : null;
    this.completedAt = json['completedAt'] ? new Date(json['completedAt']) : null;
    this.updatedAt = json['updatedAt'] ? new Date(json['updatedAt']) : null;
    this.budgetRangeHigh = json['budgetRangeHigh'];
    this.budgetRangeLow = json['budgetRangeLow'];
    this.distance = json['distance'];
    this.expirationDate = json['expirationDate'];
    this.startDate = json['startDate'];
    this.endDate = json['endDate'];
    this.isBidding = json['bidding'];
    this.conversationId = json['conversationId'];
    this.client = json['client'];
    this.lat = json['lat'];
    this.long = json['long'];

    if (json['jobTrades']) {
      json['jobTrades'].forEach(el => {
        this.trades.push(el);
      });
    }
    if (json['jobTypes']) {
      json['jobTypes'].forEach(el => {
        this.types.push(el['jobTypeId']);
      });
    }
    if (json['clientLocations']) {
      json['clientLocations'].forEach(el => {
        this.clientLocations.push(new Location().fromJSON(el['clientLocation']));
      });
    }

    return this;
  }

  public listFromJson(json: any): Array<VendorCertificate> {
    return json == null ? new Array<VendorCertificate>() : json.map((value) => new VendorCertificate().fromJSON(value));
  }
}
