// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    certusApiUri: 'https://api.bcscertus.com/v1/',
    certusApiPublicKey: 'EC0E12D3-FEBD-44A1-8C52-85C1567E56A5',
    webServiceUri: 'https://test-phoenix-api.bcsaudit.com',
    middlewareUri: 'http://localhost:51581',
    cognitoUserPoolId: 'us-east-1_cLcbdRXdS',
    cognitoUserPoolWebClientId: '420cmj64dfdantrqaqk82hrsi',
    STRIPE_KEY: 'pk_test_KD6YblVD3OD6wLXANKV9tAko',
    cognitoEndpoint: 'https://test-client-phoenix.auth.us-east-1.amazoncognito.com',
    callbackUrl: 'http://localhost:4200/callback'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
