<div class="modal-header">
	<h4 class="modal-title pull-left">Request a Review</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body m-2" [formGroup]="reviewForm">
	<div style="font-weight: 600;">Reviewer's Email Address</div>
	<input class="w-100 form-control" formControlName="email">
	<div class="pt-3 bold" style="font-weight: 600;">Message for the Reviewer:</div>
	<textarea formControlName="review" class="w-100 h-100" style="min-height: 100px;"></textarea>
</div>
<div class="row justify-content-center pb-3">
	<div class="col-3">
		<phx-button data-id="vendorRequestReviewsModalCancelBtn" text="Cancel" color="secondary" btnIcon="cancel-white" (click)="cancel()" size="medium">
		</phx-button>
	</div>
	<div class="col-4">
		<phx-button data-id="vendorRequestReviewsModalSendRequestBtn" text="Send Request" color="primary" btnIcon="send-white" [disabled]="buttonDisabled" (click)="sendReview()" size="medium">
		</phx-button>
	</div>
</div>
