<div class="left-menu">
    <div class="left-menu-header">
      <phx-icon customClass="standardImage" w="158" h="49" mb="10" mr="0" src="bcs-new-logo"></phx-icon>
      <div class="header-title px-4 mt-10" >
        <span class="mr-2">{{vendor?.name}}</span>
      </div>
    </div>
    <div class="left-menu-body">
        <div class="menu-item" routerLinkActive="active">
            <a class="parent-item" routerLink="home">
              <div class="parent-item-container">
                <phx-icon customClass="menu-icon" src="menu-home-gray" ></phx-icon>
                <div>Home</div>
              </div>
            </a>
        </div>
        <div class="menu-item" routerLinkActive="active">
            <a class="parent-item" routerLink="my-company">
              <div class="parent-item-container">
                <phx-icon customClass="menu-icon" src="menu-my-company-gray" ></phx-icon>
                <div>My Company</div>
              </div>
            </a>
        </div>
        <div class="menu-item" routerLinkActive="active">
            <a class="parent-item" routerLink="my-clients">
              <div class="parent-item-container">
                <phx-icon customClass="menu-icon" src="menu-my-clients-gray" ></phx-icon>
                <div>My Clients</div>
              </div>
            </a>
        </div>
        <div class="menu-item" routerLinkActive="active">
            <a class="parent-item" routerLink="my-jobs">
              <div class="parent-item-container">
                <phx-icon customClass="menu-icon" src="menu-my-jobs-gray" ></phx-icon>
                <div>My Jobs</div>
              </div>
            </a>
        </div>
        <div class="menu-item" routerLinkActive="active">
            <a class="parent-item" routerLink="my-job-postings">
              <div class="parent-item-container">
                <phx-icon customClass="menu-icon" src="menu-job-postings-gray" ></phx-icon>
                <div>My Job Posting</div>
              </div>
            </a>
        </div>
        <div class="menu-item" routerLinkActive="active">
            <a class="parent-item" routerLink="bcs-network">
              <div class="parent-item-container">
                <phx-icon customClass="menu-icon" src="menu-bcs-network-gray" ></phx-icon>
                <div>BCS Network</div>
              </div>
            </a>
        </div>
        <div class="menu-item" routerLinkActive="active">
            <a class="parent-item" routerLink="messages" (click)="openMessages()">
              <div class="parent-item-container">
                <phx-icon customClass="menu-icon" src="menu-messages-gray" ></phx-icon>
                <div>Messages</div>
              </div>
            </a>
        </div>
        <div class="menu-item" routerLinkActive="active">
            <a class="parent-item" routerLink="company">
              <div class="parent-item-container">
                <phx-icon customClass="menu-icon" src="settings-gray" ></phx-icon>
                <div>Company Settings</div>
              </div>
            </a>
        </div>
    </div>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Company Info</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-4">Company Name</div>
            <div class="col-8">
                <input id="vendor-name" type="text" class="form-control" placeholder="Company Name"
                    [(ngModel)]="vendorName">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-4">
                Upload Logo
            </div>
            <div class="col-8">
                <phx-upload (onFileUpload)="onFileUpload($event)"></phx-upload>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <phx-button data-id="vendorSidebarCancelBtn" text="Cancel" color="secondary" (click)="modalRef.hide()" size="medium" btnIcon="cancel-white" >
        </phx-button>
        <phx-button data-id="vendorSidebarSaveBtn" text="Save" [loading]="loading" color="primary" size="medium" btnIcon="circle-white-tick" (click)="saveVendorInfo()">
        </phx-button>
    </div>
</ng-template>
