import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { JobPosting } from '@vapp/models/job-posting-model';
@Component({
    selector: 'google-map',
    templateUrl: 'google-map.component.html'
})

export class GoogleMapComponent {
    @ViewChild('map') mapContainer: ElementRef;
    @ViewChild('searchMap') searchMap: ElementRef;
    @Input() haveButton: boolean = false;
    @Input() jobPostings: Array<JobPosting> = [];
    @Input() postingHovered: { posting: any, leave: boolean };
    @Output() markerHovered: EventEmitter<{ posting: any, leave: boolean }> = new EventEmitter<{ posting: JobPosting, leave: boolean }>();
    @Output() markerClicked: EventEmitter<JobPosting> = new EventEmitter<JobPosting>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['jobPostings']) {
            this.renderMarkers();
        }

        if (changes['postingHovered']) {
            const event = changes['postingHovered'].currentValue;
            const postingMarker = this.markersMap[event?.posting?.jobId];
            if (postingMarker) {
                if (event.leave) {
                    this.markerHoverLeave(postingMarker, event.posting);
                } else {
                    this.markerHover(postingMarker, event.posting);
                }
            }
        }
    }

    public markers: Array<google.maps.Marker> = [];
    public markersMap: any = {};
    public markerIcon = 'google-map-marker.svg'
    public map: google.maps.Map;
    // TODO set appropriate map center
    public mapOptions = {
        center: {
            lat: 40.70631559451961,
            lng: -73.62057406936488
        },
        zoom: 13,
        maxZoom: 17, //the max in-scrolling zoom (reference zoom when changing)
        minZoom: 3 //the max out-scrolling zoom (reference zoom when changing)
    }

    ngAfterViewInit() {
        this.initMap();
    }

    private initMap() {
        this.map = new google.maps.Map(this.mapContainer.nativeElement, { //set map
            center: this.mapOptions.center,
            zoom: this.mapOptions.zoom,
            minZoom: this.mapOptions.minZoom,
            mapTypeId: 'roadmap',
            disableDefaultUI: true
        });
        if (this.haveButton) {
            this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.searchMap.nativeElement);
        }
        this.renderMarkers();
    }


    // TODO get job postings and set markers on map
    renderMarkers() {
        for (let marker of this.markers) {
            marker.setMap(null);
        }
        this.markers = [];
        this.markersMap = {};
        for (let posting of this.jobPostings) {
            if (posting.lat && posting.long) {
                let options = {
                    position: { lat: posting.lat, lng: posting.long },
                    icon: this.markerIcon
                };
                let mapMarker = new google.maps.Marker(options);
                mapMarker.addListener("mouseover", () => this.markerHover(mapMarker, posting));
                mapMarker.addListener("mouseout", () => this.markerHoverLeave(mapMarker, posting));
                mapMarker.addListener('click', () => this.markerClick(posting));
                this.markersMap[posting.jobId] = mapMarker;
                this.markers.push(mapMarker);
                mapMarker.setMap(this.map);
            }
        }
    }

    markerHover(marker: google.maps.Marker, posting: JobPosting) {
        if ((!this.map.getBounds().contains(marker.getPosition()))) {
            this.map.setCenter(marker.getPosition());
        }
        marker.setAnimation(google.maps.Animation.DROP);
        this.markerHovered.next({ posting, leave: false });
    }

    markerHoverLeave(marker: google.maps.Marker, posting: JobPosting) {
        marker.setAnimation(null)
        this.markerHovered.next({ posting, leave: true });
    }

    markerClick(posting: JobPosting) {
        this.markerClicked.next(posting);
    }
}
