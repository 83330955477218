import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { PhoenixWebService } from '@vapp/providers/web.service';
import { PipeService } from 'phoenix-common';

@Component({
    selector: 'main',
    templateUrl: './main.component.html'
})
export class MainComponent {
    public loading: boolean = true;

    constructor(private pipeService: PipeService, private appService: AppService, private phoenixWebService: PhoenixWebService) {
        this.init();
    }

    public async init() {
        await this.loggedVendorInfo();
        await this.loadJobTrades();
        await this.loadJobTypes();
        this.loading = false;
    }

    public async loadJobTypes() {
        let jobTypesRes = await this.phoenixWebService.getAllJobTypes();
        this.appService.setJobTypes(jobTypesRes);
        this.pipeService.setJobTypes(jobTypesRes);
    }

    public async loadJobTrades() {
        let jobTradesRes = await this.phoenixWebService.getAllJobTrades();
        this.appService.setJobTrades(jobTradesRes);
        this.pipeService.setJobTrades(jobTradesRes);
    }

    public async loggedVendorInfo() {
        let vendor = await this.phoenixWebService.getLoggedVendorInfo();
        this.appService.setloggedVendor(vendor);
    }
}
